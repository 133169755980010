import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';
import legend from '../../../images/services/supply/240_2151657351.jpg';
import supplyOfPlant from '../../../images/services/supply/241_1_3398501.jpg';
import proc from '../../../images/services/supply/242_2439547217.jpg';
import supplier from '../../../images/services/supply/243_1962667825.jpg';

import '../../../styles/mainItem.css'

const SupplyChainServices = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Supply Chain Services</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/supply-chain-services/supply-of-plant-equipment">
                                <Card.Body>
                                    <p>Supply of Plant Equipment, Systems and Capital Parts </p>
                                    <div className='card-image-frame'>
                                        <Image src={supplyOfPlant} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/supply-chain-services/procurement-services">
                                <Card.Body>
                                    <p>Procurement Services for Material and Consumable Parts </p>
                                    <div className='card-image-frame'>
                                        <Image src={proc} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/supply-chain-services/supplier-contractor-registry">
                                <Card.Body>
                                    <p>Supplier and Contractor Registry Framework Services</p>
                                    <div className='card-image-frame'>
                                        <Image src={supplier} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SupplyChainServices
import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/40_1925391485.jpg'

import '../../styles/about.css'

const AboutUs = () => {
    return (
        <div>
            <h5 className='main-title'>About Us</h5>
            <hr />
            <p>Welcome to GEMAN POWER, where passion drives everything we do. We're thrilled to welcome you aboard. As a robust player in the industry, we are dedicated to providing innovative solutions tailored to meet your needs. From engineering and design to cutting-edge technology and service management, our offerings encompass a diverse range of markets and disciplines. With our team of passionate professionals, we bring a fresh perspective and unwavering commitment to excellence to every project. Across multiple offices and countries, we stand united in our passion to deliver exceptional service and products that make a difference.</p>
            <p className='second-title'>Improving all aspects of life!</p>
            <p>"Excellence Delivered As Promised" isn't just a tagline for us – it's our guiding principle. It's the standard we hold ourselves to, both individually and as a company. This phrase embodies our unwavering dedication to our esteemed clients, industry collaborators, and team members. It's a sincere commitment to follow through on our word. It defines the essence of our organization and serves as the foundation upon which all our partnerships and endeavors are built.</p>
            <p>Lately, we've been at the forefront of driving transformation in infrastructure and enhancing communities across numerous countries. We champion sustainability and innovation in all our endeavors, ensuring that we deliver results while upholding our responsibility as custodians of the environment.</p>
            <p className='second-title'>We're Unique.</p>
            <p>Partnering with us means having our entire team dedicated to tackling your challenges and guaranteeing the success of your projects. We're passionate about our work, and it reflects in both the quality of our output and our relationship with you. We're confident you'll appreciate working with us because we're driven by a genuine desire to provide you with reliable, high-quality service. </p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default AboutUs
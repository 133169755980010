import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';

import legend from '../../../images/services/epc/260_398992222.jpg'
import energy from '../../../images/services/epc/261_2343561737.jpg'
import renew from '../../../images/services/epc/262_112485596.jpg'

import '../../../styles/mainItem.css'

const EpcServices = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>EPC Services</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Energy & Petroleum Plants</p>
                                    <div className='card-image-frame'>
                                        <Image src={energy} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/renewable-power-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Renewable Power Plants</p>
                                    <div className='card-image-frame'>
                                        <Image src={renew} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default EpcServices
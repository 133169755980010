import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';
import legend from '../../../images/services/consulting/210_1108431911.jpg';
import power from '../../../images/services/consulting/211_271820135.jpg';
import industrial from '../../../images/services/consulting/212_679978150.jpg';
import naturalGas from '../../../images/services/consulting/213_1922037830.jpg';
import airAndGas from '../../../images/services/consulting/214_365136905.jpg';
import highPower from '../../../images/services/consulting/215_1971671024.jpg';
import epcm from '../../../images/services/consulting/216_609092369.jpg';
import '../../../styles/mainItem.css'

const Consulting = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4' >Consulting</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/consulting/power-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Power Plants</p>
                                    <div className='card-image-frame'>
                                        <Image src={power} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/consulting/industrial-projects" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Industrial Projects</p>
                                    <div className='card-image-frame'>
                                        <Image src={industrial} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/consulting/natural-gas-pipeline-construction" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Natural Gas Pipeline Construction</p>
                                    <div className='card-image-frame'>
                                        <Image src={naturalGas} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/consulting/air-and-gas" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Air and Gas Insulate Substation</p>
                                    <div className='card-image-frame'>
                                        <Image src={airAndGas} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/consulting/high-power-energy" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>High Power Energy Transmission Overhead Line</p>
                                    <div className='card-image-frame'>
                                        <Image src={highPower} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/consulting/epcm" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>EPCM</p>
                                    <div className='card-image-frame'>
                                        <Image src={epcm} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Consulting
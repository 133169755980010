import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';
import legend from '../../../images/services/fieldServices/230_2158048149.jpg';
import man from '../../../images/services/fieldServices/231_1740140642.jpg';
import equipment from '../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site from '../../../images/services/fieldServices/233_1080243251.jpg';
import hse from '../../../images/services/fieldServices/234_383464744.jpg';
import construction from '../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning from '../../../images/services/fieldServices/236_2294645377.jpg';

import '../../../styles/mainItem.css'

const FieldServices = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Field Services</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start mt-4'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/field-services/manpower-supply" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Manpower Supply</p>
                                    <div className='card-image-frame'>
                                        <Image src={man} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/field-services/equipment-tooling-fleet-services" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Equipment, Tooling & Fleet Services</p>
                                    <div className='card-image-frame'>
                                        <Image src={equipment} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/field-services/site-management" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Site Management</p>
                                    <div className='card-image-frame'>
                                        <Image src={site} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/field-services/health-safety-env-services" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>HSE Services </p>
                                    <div className='card-image-frame'>
                                        <Image src={hse} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/field-services/construction-services-and-execution" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Construction Services and Execution </p>
                                    <div className='card-image-frame'>
                                        <Image src={construction} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/field-services/commissioning-turnover" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Commissioning & Turnover</p>
                                    <div className='card-image-frame'>
                                        <Image src={commissioning} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default FieldServices
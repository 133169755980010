import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import asset from '../images/asset-management.jpeg';
import legend from '../images/home/newLegend2.jpeg';
import hydrogen from '../images/home/24_2168999393.jpg';
import energy from '../images/home/25_2084987944.jpg';
import '../styles/mainItem.css'

const EnergyTransition = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Products</h1>
                    <hr />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/products/hydrogen/index" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Hydrogen Production & Storage</p>
                                    <div className='card-image-frame'>
                                        <Image src={hydrogen} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/products/energy/index" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Energy Storage</p>
                                    <div className='card-image-frame'>
                                        <Image src={energy} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default EnergyTransition
import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/47_1544304599.jpg'

import '../../styles/about.css'

const CorporateSafetyProgram = () => {
    return (
        <div>
            <h5 className='main-title'>Corporate Safety Program</h5>
            <hr />
            <p>
                At GEMAN POWER, safety is everyone's responsibility, regardless of their location or role. We prioritize the highest level of safety in both client projects and internal operations by providing cutting-edge protective equipment, clearly defined procedures, and specialized training.
            </p>
            <p className='second-title'>
                Safety is Our First Priority.
            </p>
            <p>
                The nature of our work exposes us to potential hazards, but we are dedicated to safeguarding the well-being of our clients, their customers, our employees, and the communities we serve. Our commitment to safety is evident in our rigorous policies, processes, training programs, and the adoption of advanced technologies.
            </p>
            <p className='second-title'>
                Key Features of Our Safety Program:

            </p>
            <ul>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Low Incident Rates. </span>
                    Our total recordable incident and experience modification rates are below industry averages, reflecting our dedication to safety excellence.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Employee Empowerment.</span>  We empower employees with the authority to halt work <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>(stop work authority) </span>
                    if they identify safety concerns, ensuring immediate action to mitigate risks.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Disciplinary policy. </span>
                    Our safety-related disciplinary policy motivates staff to adhere to established safety procedures, fostering a culture of accountability.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Performance Goals. </span>
                    Safety-related performance goals are set for all employees, reinforcing our commitment to safety at all levels.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Safety Stand Downs. </span>
                    Company-wide safety stand downs facilitate open discussions on strategic safety goals, promoting collective progress.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Safety Committee. </span>
                    Comprising employees and executives, our Safety Committee oversees safety initiatives and reports progress to the Board of Directors.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Safety Month. </span>
                    We celebrate safety culture annually with a companywide safety month, promoting awareness and recognition.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Local Safety Coordinators. </span>
                    Each office has dedicated safety coordinators who serve as valuable resources for employees.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Toolbox Talks. </span>
                    Regular toolbox talks facilitate health and safety discussions on job sites, fostering continuous improvement.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Communication Tools. </span>
                    We maintain a Corporate Safety Intranet page and provide weekly safety tips to ensure ongoing awareness.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Annual Training. </span>
                    All employees undergo annual safety awareness training to stay informed about basic safety principles and upcoming initiatives.
                </li>
                <li>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Job-Specific Courses.</span>
                    We offer job-specific safety courses to equip employees with relevant training tailored to their roles.
                </li>
                <li style={{ marginBottom: '30px' }}>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Diverse Training Offerings. </span>
                    Our safety training spans first aid, lifting & rigging, and specialized client-specific courses to address various needs.
                </li>
                <p style={{ marginBottom: '30px' }}>At GEMAN POWER, safety is ingrained in our culture, guiding our actions and decisions every day.</p>
            </ul>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default CorporateSafetyProgram
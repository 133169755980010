import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/epc/260_398992222.jpg';

import oilGas4 from '../../../../../images/services/epc/energy/261_1_271820135.jpg';
import oilNatural4 from '../../../../../images/services/epc/energy/261_2_113550355.jpg';
import bioFuel4 from '../../../../../images/services/epc/energy/261_3_1054225235.jpg';
import lng4 from '../../../../../images/services/epc/energy/261_4_1346316614.jpg';
import petro4 from '../../../../../images/services/epc/energy/261_5_549195883.jpg';
import '../../../../../styles/subItem.css'

const OilGasPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Oil & Gas Power Plant </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={oilGas4} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER, in collaboration with its global partners, specializes in energy generation and financing initiatives within the gas and petroleum sectors. Our comprehensive services span project development, implementation, equipment sales, and after-sales maintenance and support.</p>
                        <p>As a premier engineering and service company, GEMAN POWER is renowned for its expertise in establishing infrastructure and integrated industrial projects. From initial planning and design phases to engineering, procurement, commissioning, and handover, we provide end-to-end solutions.</p>
                        <p>Backed by over 15 years of demonstrated experience, GEMAN POWER has a proven track record of successfully delivering large-scale, complex projects. Our team's unwavering commitment to excellence ensures that projects are completed to the highest standards, meeting guaranteed performance criteria within specified timelines.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Energy & Chemicals </h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-natural-gas-pipelines" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil/Natural Gas Pipelines</p>
                                <div className='card-image-frame'>
                                    <Image src={oilNatural4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/biofuel-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>BioFuel Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={bioFuel4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/lng" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Liquefied Natural Gas</p>
                                <div className='card-image-frame'>
                                    <Image src={lng4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/petroleum-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Petroleum Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={petro4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default OilGasPower
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/consulting/210_1108431911.jpg';
import power2 from '../../../../images/services/consulting/211_271820135.jpg';
import industrial2 from '../../../../images/services/consulting/212_679978150.jpg';
import naturalGas2 from '../../../../images/services/consulting/213_1922037830.jpg';
import airAndGas2 from '../../../../images/services/consulting/214_365136905.jpg';
import highPower2 from '../../../../images/services/consulting/215_1971671024.jpg';
import epcm2 from '../../../../images/services/consulting/216_609092369.jpg';
import '../../../../styles/subItem.css'

const PowerPlants = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Power Plants</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={power2} className='subitem-img' />
                        </div>
                        <p>We specialize in offering comprehensive power plant consulting solutions encompassing design, analysis, startup/commissioning, and structural/safety evaluations across a diverse range of energy sources including fossil fuel, combined cycle, hydroelectric, renewables, solar, solid waste, and biomass plants.</p>
                        <p>Our services extend to supporting plant outages, conducting assessments, troubleshooting/repair, and ensuring the fitness for service of equipment.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Consulting</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/industrial-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Industrial Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={industrial2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/natural-gas-pipeline-construction" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Natural Gas Pipeline Construction</p>
                                <div className='card-image-frame'>
                                    <Image src={naturalGas2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/air-and-gas" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Air and Gas insulate Substation</p>
                                <div className='card-image-frame'>
                                    <Image src={airAndGas2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/high-power-energy" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>High Power Energy Transmission Overhead Line</p>
                                <div className='card-image-frame'>
                                    <Image src={highPower2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/epcm" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>EPCM</p>
                                <div className='card-image-frame'>
                                    <Image src={epcm2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default PowerPlants
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import natural2 from '../../../../../images/services/operation/251_1_1722045232.jpeg';
import coal2 from '../../../../../images/services/operation/251_2_1137606449.jpg';
import diesel2 from '../../../../../images/services/operation/251_3_607855838.jpg';
import '../../../../../styles/subItem.css'

const CoalFired = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Coal Fired Thermal Power Plants </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={coal2} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER Smart Operations offers comprehensive Operation and Maintenance (O&M) solutions tailored specifically for coal-fired power plant owners and investors. Our approach emphasizes sustainability and safety, ensuring that your plant operates at peak performance while minimizing environmental impact.</p>
                        <p>We are dedicated to delivering best-in-class performance and maximizing shareholder value by optimizing operation and maintenance resources throughout the entire asset lifecycle. From routine maintenance to emergency repairs, our team is committed to ensuring the long-term success and profitability of your power plant investment. With GEMAN POWER Smart Operations, you can trust that your plant is in capable hands, poised for sustainable growth and success.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Power Plants</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/natural-gas-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p> Natural Gas Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={natural2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/diesel-fired" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Diesel-Fired Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={diesel2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default CoalFired
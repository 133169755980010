import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/consulting/210_1108431911.jpg';
import power4 from '../../../../images/services/consulting/211_271820135.jpg';
import industrial4 from '../../../../images/services/consulting/212_679978150.jpg';
import naturalGas4 from '../../../../images/services/consulting/213_1922037830.jpg';
import airAndGas4 from '../../../../images/services/consulting/214_365136905.jpg';
import highPower4 from '../../../../images/services/consulting/215_1971671024.jpg';
import epcm4 from '../../../../images/services/consulting/216_609092369.jpg';
import '../../../../styles/subItem.css'

const NaturalGas = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Natural Gas Pipeline Construction</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={naturalGas4} className='subitem-img' />
                        </div>
                        <p>With a wealth of expertise in Construction Project Management across all phases of the project lifecycle, we offer impartial and objective advisory services. Our focus spans strategic, technical, and commercial interests to ensure successful project delivery. This includes specialized capabilities in construction of natural gas pipelines. Our highly skilled team is dedicated to guiding clients through every stage of their projects with precision and effectiveness.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Consulting</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={power4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/industrial-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Industrial Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={industrial4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/air-and-gas" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Air and Gas insulate Substation</p>
                                <div className='card-image-frame'>
                                    <Image src={airAndGas4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/high-power-energy" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>High Power Energy Transmission Overhead Line</p>
                                <div className='card-image-frame'>
                                    <Image src={highPower4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/epcm" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>EPCM</p>
                                <div className='card-image-frame'>
                                    <Image src={epcm4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default NaturalGas
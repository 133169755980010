import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/engServices/220_2114019077.jpg';
import tech3 from '../../../../images/services/engServices/221_1887083413.jpg';
import design3 from '../../../../images/services/engServices/222_1504702805.jpg';
import project3 from '../../../../images/services/engServices/223_2293858001.jpg';
import '../../../../styles/subItem.css'

const ProjectProgram = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Project & Program Management</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={project3} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER is trusted by customers to successfully execute complex projects in demanding locations across the globe. Serving as the project manager, we seamlessly integrate engineering, procurement, and construction (EPC) functions to exceed client expectations.</p>
                        <p>With extensive program management experience, we frequently assume the role of overall program manager for large-scale capital projects involving substantial investments in goods and services. Acting as the client's primary point of contact, we orchestrate the activities of all contractors and subcontractors, fostering collaboration and cohesion among diverse teams from various backgrounds and cultures. This holistic approach ensures the overall success of the program, delivering results that align closely with our clients' objectives.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Engineering Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design/technical-support" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Technical Support (24/7 ) </p>
                                <div className='card-image-frame'>
                                    <Image src={tech3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design/design-manufacturing" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Design & Manufacturing</p>
                                <div className='card-image-frame'>
                                    <Image src={design3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default ProjectProgram
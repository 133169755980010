import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/42_1499939441.jpg'

import '../../styles/about.css'

const CompaniesAffiliates = () => {
    return (
        <div>
            <h5 className='main-title'>Companies & Affiliates</h5>
            <hr />
            <p>Within the GEMAN POWER family of companies, we boast a wealth of specialized resources and expertise, enabling us to tackle even the most intricate challenges and help our clients reach their strategic and operational objectives. Explore us to discover our tailored solutions, perfectly suited to meet your requirements.</p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default CompaniesAffiliates
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/consulting/210_1108431911.jpg';
import power7 from '../../../../images/services/consulting/211_271820135.jpg';
import industrial7 from '../../../../images/services/consulting/212_679978150.jpg';
import naturalGas7 from '../../../../images/services/consulting/213_1922037830.jpg';
import airAndGas7 from '../../../../images/services/consulting/214_365136905.jpg';
import highPower7 from '../../../../images/services/consulting/215_1971671024.jpg';
import epcm7 from '../../../../images/services/consulting/216_609092369.jpg';
import '../../../../styles/subItem.css'

const Epcm = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>EPCM</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={epcm7} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER is committed to prioritizing the best interests of our clients across various industries, offering specialized expertise. Central to our ability to deliver on this promise is our team of experts, carefully selected for their proficiency and experience in meeting our clients' needs. Many of our teammates bring valuable experience from working directly within client organizations, providing us with invaluable insights into operational, production, and maintenance requirements.</p>
                        <p>In our approach to audits and economic justifications, we emphasize the importance of timely and cost-effective solutions while prioritizing safety in design, constructability, and operational efficiency. By focusing on these core principles, we ensure that our clients receive tailored solutions that not only meet their immediate needs but also contribute to long-term success and sustainability.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Consulting</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={power7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/industrial-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Industrial Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={industrial7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/natural-gas-pipeline-construction" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Natural Gas Pipeline Construction</p>
                                <div className='card-image-frame'>
                                    <Image src={naturalGas7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/high-power-energy" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>High Power Energy Transmission Overhead Line</p>
                                <div className='card-image-frame'>
                                    <Image src={highPower7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/air-and-gas" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Air and Gas insulate Substation</p>
                                <div className='card-image-frame'>
                                    <Image src={airAndGas7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Epcm
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import natural3 from '../../../../../images/services/operation/251_1_1722045232.jpeg';
import coal3 from '../../../../../images/services/operation/251_2_1137606449.jpg';
import diesel3 from '../../../../../images/services/operation/251_3_607855838.jpg';
import '../../../../../styles/subItem.css'

const DieselFired = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Diesel-Fired Power Plants</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={diesel3} className='subitem-img' />
                        </div>
                        <p>Choosing an operation and maintenance solution with GEMAN POWER entails forming a strategic partnership aimed at achieving shared productivity and profitability goals for your company. This comprehensive business solution encompasses all aspects of day-to-day operations, maintenance, and administrative tasks.</p>
                        <p>Our objective is to optimize the operational lifespan of your installation and enhance your return on investment. Tailored to your unique requirements, our solution includes performance and lifecycle cost guarantees to ensure maximum efficiency and value. With GEMAN POWER, you can expect a customized approach that drives success and longevity for your operations.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Power Plants</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/natural-gas-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p> Natural Gas Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={natural3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/coal-fired" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Coal Fired Thermal Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={coal3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default DieselFired
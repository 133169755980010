import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/engServices/220_2114019077.jpg';
import tech4 from '../../../../images/services/engServices/221_1887083413.jpg';
import design4 from '../../../../images/services/engServices/222_1504702805.jpg';
import project4 from '../../../../images/services/engServices/223_2293858001.jpg';
import '../../../../styles/subItem.css'

const EngineeringReverse = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Design & Manufacturing</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={design4} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER leverages its Engineering and Modeling Capabilities for Advanced Process Systems and Parts, delivering tailored solutions to clients.</p>
                        <p>Collaborating closely with clients, GEMAN POWER offers expertise in developing new process technologies through a comprehensive suite of advanced process modeling services. These encompass physical and thermodynamic properties, reaction kinetics, and equipment modeling, culminating in the creation of specialized databases and comprehensive flowsheet models.</p>
                        <p>Our consulting services span process design, optimization, and plant troubleshooting, ensuring seamless integration of innovative solutions into existing operations.</p>
                        <p>The inherent value of this service lies in the provision of high-quality data packages for process modeling, rapid development timelines, comprehensive documentation of models for engineering understanding, and robust security measures to safeguard proprietary information.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Engineering Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design/technical-support" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Technical Support (24/7 ) </p>
                                <div className='card-image-frame'>
                                    <Image src={tech4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design/project-program-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Project & Program Management </p>
                                <div className='card-image-frame'>
                                    <Image src={project4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default EngineeringReverse
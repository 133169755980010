import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';

import legend from '../../../../../images/services/epc/260_398992222.jpg';
import solar from '../../../../../images/services/epc/renewable/262_1_1728639280.jpg';
import wind from '../../../../../images/services/epc/renewable/262_2_1564602832.jpg';
import '../../../../../styles/subItem.css'

const SolarPowerPlant = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Solar Power Plant</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={solar} className='subitem-img' />
                        </div>
                        <p>Your Solar Energy EPC Partner for Proven Solutions.</p>
                        <p>With a wealth of industry-leading experience, GEMAN POWER Solutions stands as a pioneering service provider in Consultancy, solar energy EPC (Engineering – Procurement – Construction), and Operation & Maintenance. Our specialized team is dedicated to planning and implementing solar and wind power plants, collaborating with clients worldwide to harness renewable energy for the betterment of society.</p>
                        <p>Committed to sustainability and environmental stewardship, our eco-friendly solutions are tailored for maximum energy output and efficiency while minimizing any adverse environmental impacts. Backed by an international and professional team, we offer comprehensive support to our customers, ensuring best-in-class results from the initial design and realization phases to the final operational implementation. Trust GEMAN POWER Solutions to be your partner in driving forward the transition to clean, renewable energy solutions.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Renewable Power Plants</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/renewable-power-plants/wind-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Wind Power Plant </p>
                                <div className='card-image-frame'>
                                    <Image src={wind} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SolarPowerPlant
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/consulting/210_1108431911.jpg';
import power3 from '../../../../images/services/consulting/211_271820135.jpg';
import industrial3 from '../../../../images/services/consulting/212_679978150.jpg';
import naturalGas3 from '../../../../images/services/consulting/213_1922037830.jpg';
import airAndGas3 from '../../../../images/services/consulting/214_365136905.jpg';
import highPower3 from '../../../../images/services/consulting/215_1971671024.jpg';
import epcm3 from '../../../../images/services/consulting/216_609092369.jpg';
import '../../../../styles/subItem.css'

const IndustrialProjects = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Industrial Projects</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={industrial3} className='subitem-img' />
                        </div>
                        <p>At GEMAN POWER, our team consists of construction experts with backgrounds in general contracting, specializing in the meticulous management of every aspect of capital improvement projects. From planning and design to procurement, construction, and post-construction phases, we provide comprehensive oversight. </p>
                        <p>With a dedicated focus on industrial construction, we prioritize attention to detail, ensuring that every aspect of a project is carefully managed to support our clients in maintaining focus on their core business operations.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Consulting</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={power3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/natural-gas-pipeline-construction" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Natural Gas Pipeline Construction</p>
                                <div className='card-image-frame'>
                                    <Image src={naturalGas3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/air-and-gas" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Air and Gas insulate Substation</p>
                                <div className='card-image-frame'>
                                    <Image src={airAndGas3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/high-power-energy" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>High Power Energy Transmission Overhead Line</p>
                                <div className='card-image-frame'>
                                    <Image src={highPower3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/epcm" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>EPCM</p>
                                <div className='card-image-frame'>
                                    <Image src={epcm3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default IndustrialProjects
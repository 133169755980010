import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/43_2214835483.jpg'

const Sustainability = () => {
    return (
        <div>
            <h5 className='main-title'>Sustainability</h5>
            <hr />
            <p>We are dedicated to delivering sustainable outcomes that not only bring value and impact to our clients but also preserve natural resources and contribute to the well-being of both global and local communities.</p>
            <p>Diverse and evolving, our array of sustainable services, programs, and initiatives align with our vision to enhance both our communities and the world at large. This commitment is underscored by our substantial investment in the development of intelligent designs, cutting-edge technologies, and streamlined systems, all geared towards promoting environmentally responsible solutions across our internal operations, community engagements, and your projects.</p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default Sustainability
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import contactImg from '../images/contact/61_2341449037.jpeg';
import phone from '../images/contact/phone.png';
import email from '../images/contact/email.png';
import gps from '../images/contact/gps.png';
import emailjs from '@emailjs/browser';
import twitter from '../images/social/twitter.png';
import linkedin from '../images/social/linkedin.png';
import facebook from '../images/social/facebook.png';
import instagram from '../images/social/instagram.png';
import '../styles/contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const form = useRef();

    useEffect(() => {
        emailjs.init('jU6qtxyZcwKWLmVXA'); // Initialize EmailJS with your public key
    }, []); // Empty dependency array ensures it runs only once on component mount

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_30begxh',  // service id
            'template_mqjc8gh', // template id
            form.current,
            'audLyh5mM0NotrF7e' // public key here
        )
            .then((result) => {
                alert('Thank you ' + formData.name + ' 😊 ' + '!');
                // Smooth scroll to the top of the page
                window.scrollTo(0, 0)
            }, (error) => {
                alert(error.text);
            });

        setFormData({ name: '', email: '', subject: '', message: '', phone: '' });
    };

    return (
        <div className="contact-container">
            <Image src={contactImg} fluid className='contact-img' />
            <Container className='container-form'>
                <Row className='mt-4 justify-content-md-center'>
                    <Col md={6}>
                        <h1 className='contact-header'>Contact Form</h1>
                        <Form ref={form} onSubmit={handleSubmit}>
                            <Form.Group as={Row}>
                                <Col sm={6}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name Surname"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                            </Form.Group>


                            <Form.Group as={Row}>
                                <Col sm={6}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">

                                <Col sm={12}>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        maxRows={10}
                                        placeholder="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                            </Form.Group>

                            <Button variant="primary" type="submit" className="bg-black text-white">
                                Send
                            </Button>
                        </Form>
                    </Col>
                    <Col md={6} >
                        <ul className='contact-locations'>
                            <div className='contact-element'>

                                <div className='iconBg'>
                                    <img src={gps} className='contactIcon' alt='gps' />
                                </div>
                                <li>

                                    <h1 >USA Office</h1>
                                    <p>
                                        20900 NE 30 th Avenue, Suite 200 , Miami, Florida, 33180 
                                    </p>
                                    <p>The United States of America</p>
                                </li>
                            </div>

                            <div className='contact-element'>

                                <div className='iconBg'>
                                    <img src={gps} className='contactIcon' alt='gps' />
                                </div>

                                <li>
                                    <h1 >Europe Office</h1>
                                    <p>
                                        Industrial Park Kleefse Waard, Connectr Energy Innovation Center, Westervoortsedijk 78 - KB, 6827 AV, Arnhem
                                    </p>
                                    <p>The Netherlands</p>

                                </li>
                            </div>

                            <div className='contact-element'>
                                <div className='iconBg'>
                                    <img src={gps} className='contactIcon' alt='Facebook' />
                                </div>

                                <li>
                                    <h1 >Türkiye Office</h1>
                                    <p>
                                        YTÜ Yıldız Teknopark, Çiftehavuzlar Mah. Eski Londra Asfaltı Cad. Kuluçka Mrk. A 1 Blok No: 151 /1 C, İç Kapı No: B 34 , 34220 , İstanbul
                                    </p>
                                    <p>Türkiye</p>

                                </li>
                            </div>


                            <div className='contact-element'>
                                <div className='iconBg'>
                                    <img src={email} className='contactIcon' alt='Facebook' />
                                </div>
                                <li>
                                    <h1 >Email</h1>
                                    <p> <a href="mailto:info@gemanpower.com">info@gemanpower.com</a></p>
                                </li>
                            </div>

                            <div className='contact-element'>
                                <div className='iconBg'>
                                    <img src={phone} className='contactIcon' alt='Facebook' />
                                </div>
                                <li>
                                    <h1 >Phone</h1>
                                    <p><a href="tel:+18882484734">+1 888 248 4734</a></p>
                                    <p><a href="tel:+31854830988">+31 854 830 988</a></p>
                                    <p><a href="tel:+905320697579">+90 532 069 7579</a></p>
                                </li>
                            </div>

                        </ul>

                        <div className='custom-icons'>
                            <div className='icon-bg'>
                                <a href="https://twitter.com/GemanPower" target="_blank" rel="noopener noreferrer">
                                    <img src={twitter} className='contact-social-media-icon' alt='Twitter' />
                                </a>
                            </div>
                            <div className='icon-bg'>
                                <a href="https://www.linkedin.com/company/gemanpower/about/" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedin} className='contact-social-media-icon' alt='LinkedIn' />
                                </a>
                            </div>
                            <div className='icon-bg'>
                                <a href="" target="_blank" rel="noopener noreferrer">
                                    <img src={facebook} className='contact-social-media-icon' alt='Facebook' />
                                </a>
                            </div>
                            <div className='icon-bg'>
                                <a href="" target="_blank" rel="noopener noreferrer">
                                    <img src={instagram} className='contact-social-media-icon' alt='Instagram' />
                                </a>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Contact;

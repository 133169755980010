import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/supply/240_2151657351.jpg';
// import supplyOfPlant3 from '../../../../images/services/supply/241_1_3398501.jpg';
import proc3 from '../../../../images/services/supply/242_2439547217.jpg';
import supplier3 from '../../../../images/services/supply/243_1962667825.jpg';
import img1 from '../../../../images/services/supply/241_1_3398501.jpg';
import img2 from '../../../../images/services/supply/241_2_112485596.jpg';
import img3 from '../../../../images/services/supply/241_3_2229932661.jpg';
import img4 from '../../../../images/services/supply/241_4_134076992.jpg';

import '../../../../styles/subItem.css'

const SupplyOfPlant = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Supply of Plant Equipment, Systems and Capital Parts </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={img1} className='subitem-img' />
                        </div>
                        <div className='image-frame'>
                            <Image src={img2} className='subitem-img' />
                        </div>
                        <div className='image-frame'>
                            <Image src={img3} className='subitem-img' />
                        </div>
                        <div className='image-frame'>
                            <Image src={img4} className='subitem-img' />
                        </div>
                        <p>At GEMAN POWER, we specialize in providing a comprehensive range of power plant equipment, systems, and capital parts to meet the diverse needs of our clients. From heavy machinery to intricate systems and critical components, we ensure the supply of high-quality equipment that is essential for the success of your projects. With our extensive network of suppliers and our commitment to excellence, you can trust us to deliver reliable solutions that optimize performance, enhance productivity, and drive success for your operations.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Supply Chain Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services/procurement-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Procurement Services for Material and Consumable Parts </p>
                                <div className='card-image-frame'>
                                    <Image src={proc3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services/supplier-contractor-registry" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Supplier and Contractor Registry Framework Services</p>
                                <div className='card-image-frame'>
                                    <Image src={supplier3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default SupplyOfPlant
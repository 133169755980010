import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';
import legend from '../../../images/products/30_2041844726.jpg';
import img1 from '../../../images/products/31_2168999393.jpg';
import img2 from '../../../images/products/32_1936218154.jpg';
import '../../../styles/subItem.css'

const SubHydrogen = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4 mb-4">
                    <Col>
                        <h2>Hydrogen Production & Storage</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={img2} className='subitem-img' />
                        </div>
                        <p>Hydrogen has emerged as a pivotal player in the global transition towards sustainable energy solutions. With widespread governmental support and significant investments pouring into innovative technologies, the race to develop efficient methods for hydrogen production and storage has intensified. This R&D program delves into a groundbreaking project undertaken by GEMAN POWER, aimed at revolutionizing hydrogen production and storage to meet the demands of a rapidly evolving energy landscape.</p>
                        <p>GEMAN POWER embarked on a mission to spearhead the development of cutting-edge solutions for hydrogen production and storage. Leveraging its expertise in engineering, procurement, and construction, the project aimed to push the boundaries of existing technology and pave the way for a hydrogen-powered future.</p>
                       

                        <div className='image-frame'>
                            <Image src={img1} className='subitem-img' />
                        </div>
                        
                        
                    </Col>
                </Row>
                {/* Second Row */}
                {/* <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Hydrogen Production & Storage</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/products/hydrogen" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Hydrogen Production & Storage</p>
                                <div className='card-image-frame'>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row> */}
            </Container>
        </div>
    )
}

export default SubHydrogen
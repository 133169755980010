import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/fieldServices/230_2158048149.jpg';
import man6 from '../../../../images/services/fieldServices/231_1740140642.jpg';
import equipment6 from '../../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site6 from '../../../../images/services/fieldServices/233_1080243251.jpg';
import hse6 from '../../../../images/services/fieldServices/234_383464744.jpg';
import construction6 from '../../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning6 from '../../../../images/services/fieldServices/236_2294645377.jpg';
import '../../../../styles/subItem.css'

const Manpower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };


    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Manpower Supply</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={man6} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER offers a Global Manpower Service Network across the globe in variety of skill sets within spanning industries such as Oil and Gas, Power, and Renewables.</p>
                        <p>Our internationally acclaimed team boasts extensive experience in temporary hiring solutions and contractor workforce management. Comprising discipline-specific consultants deeply familiar with their respective markets, we bring unparalleled expertise to every project.</p>
                        <p>Partnering with the most sought-after leadership capacities, engineers, specialist, technical advisors, supervisors, and craft team members worldwide, we diligently work in the global talent pool to identify the precise expertise needed to propel your business or project forward.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Field Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/equipment-tooling-fleet-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Equipment, Tooling & Fleet Services</p>
                                <div className='card-image-frame'>
                                    <Image src={equipment6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/site-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Site Management</p>
                                <div className='card-image-frame'>
                                    <Image src={site6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/health-safety-env-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>HSE Services </p>
                                <div className='card-image-frame'>
                                    <Image src={hse6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/construction-services-and-execution" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Construction Services and Execution </p>
                                <div className='card-image-frame'>
                                    <Image src={construction6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/commissioning-turnover" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Commissioning & Turnover</p>
                                <div className='card-image-frame'>
                                    <Image src={commissioning6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default Manpower
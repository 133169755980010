import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import natural4 from '../../../../../images/services/operation/251_1_1722045232.jpeg';
import coal4 from '../../../../../images/services/operation/251_2_1137606449.jpg';
import diesel4 from '../../../../../images/services/operation/251_3_607855838.jpg';
import '../../../../../styles/subItem.css'

const NaturalGasPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Natural Gas Power Plants (Simple And Combined-Cycle)</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={natural4} className='subitem-img' />
                        </div>
                        <p>At GEMAN POWER, our team of experienced and qualified professionals manages your assets with care. We provide comprehensive coverage of all aspects and activities of a power plant in a customized and integrated manner.</p>
                        <p>Our focus is on creating value for our clients by minimizing operational risks and optimizing output and profitability. We uphold a superior standard of safety and quality in all our endeavors. Additionally, as an alternative to relying solely on an OEM (Original Equipment Manufacturer), GEMAN POWER offers expertise to support plant management.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Power Plants</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/coal-fired" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Coal Fired Thermal Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={coal4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/diesel-fired" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Diesel-Fired Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={diesel4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>



                </Row>
            </Container>
        </div>
    )
}

export default NaturalGasPower
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/fieldServices/230_2158048149.jpg';
import man4 from '../../../../images/services/fieldServices/231_1740140642.jpg';
import equipment4 from '../../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site4 from '../../../../images/services/fieldServices/233_1080243251.jpg';
import hse4 from '../../../../images/services/fieldServices/234_383464744.jpg';
import construction4 from '../../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning4 from '../../../../images/services/fieldServices/236_2294645377.jpg';
import '../../../../styles/subItem.css'

const Equipment = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Equipment, Tooling & Fleet Services </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={equipment4} className='subitem-img' />
                        </div>
                        <p>Operations Support Services of GEMAN POWER supplies and manages the logistics of all equipment, tooling, and specialized tooling essential for construction sites. By streamlining operations, standardizing equipment, and adhering to timelines and budgets, we enhance productivity, minimize costs, and ensure timely project completion.</p>
                        <p>We aim to drive down operational expenses and to enhance efficiency. This enables our customer to reallocate financial resources and manpower to their core business activities.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Field Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/manpower-supply" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Manpower Supply</p>
                                <div className='card-image-frame'>
                                    <Image src={man4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/site-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Site Management</p>
                                <div className='card-image-frame'>
                                    <Image src={site4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/health-safety-env-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>HSE Services </p>
                                <div className='card-image-frame'>
                                    <Image src={hse4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/construction-services-and-execution" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Construction Services and Execution </p>
                                <div className='card-image-frame'>
                                    <Image src={construction4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/commissioning-turnover" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Commissioning & Turnover</p>
                                <div className='card-image-frame'>
                                    <Image src={commissioning4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default Equipment
import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/48_1052022248.jpg'

import '../../styles/about.css'

const Leadership = () => {
    return (
        <div>
            <h5 className='main-title'>Leadership</h5>
            <hr />
            <p>At GEMAN POWER, our leaders are stewards, guiding the company with a steadfast commitment to principles of strategic investment and growth, innovation, ethical conduct, and technical excellence.</p>
            <p className='second-title'>Guiding Principles</p>
            <p>Our senior leadership are dedicated to astonishing our clients and earning their trust, all while fostering a fulfilling work environment for our employees.</p>
            <p className='second-title'>Dynamic Leadership</p>
            <p>The leadership of GEMAN POWER comprises dynamic cadre of leaders some of the industry's finest talent each brings their own unique vision to the table. They are innovators, problem solvers, thought leaders and educators, fostering a culture of excellence for our employees and your projects.</p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default Leadership
import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import divider from '../images/about/41_1524433004.jpg'

import AboutUs from '../components/About/AboutUs';
import CompaniesAffiliates from '../components/About/CompaniesAffiliates';
import Sustainability from '../components/About/Sustainability';
import History from '../components/About/History';
import Quality from '../components/About/Quality';
import VisionMission from '../components/About/VisionMission';
import CorporateSafetyProgram from '../components/About/CorporateSafetyProgram';
import Leadership from '../components/About/Leadership';
import Research from '../components/About/Research';
import AboutSideMenu from '../components/About/AboutSideMenu';

import '../styles/about.css'

const About = () => {
  const [activeMenuItem, setActiveMenuItem] = useState('About Us');

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    // Smooth scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const renderContent = () => {
    switch (activeMenuItem) {
      case 'About Us':
        return (
          <AboutUs />
        );
      case 'Companies & Affiliates':
        return (
          <CompaniesAffiliates />
        );
      case 'Sustainability':
        return (
          <Sustainability />
        );
      case 'History':
        return (
          <History />
        );
      case 'Quality':
        return (
          <Quality />
        );
      case 'Vision, Mission & Values':
        return (
          <VisionMission />
        );
      case 'Corporate Safety Program':
        return (
          <CorporateSafetyProgram />
        );
      case 'Leadership':
        return (
          <Leadership />
        );
      case 'Research & Innovation':
        return (
          <Research />
        );
      default:
        return null;
    }
  };


  return (
    <div className='about-page'>
      <Image src={divider} fluid className="about-img" />
      <Container>
        <Row className='mt-4'>
          {/* For large screens, menu is on the left and content is on the right */}
          <Col lg={3} className='d-none d-lg-block'>
            <AboutSideMenu handleMenuItemClick={handleMenuItemClick} activeMenuItem={activeMenuItem} />
          </Col>
          <Col lg={9} className='d-none d-lg-block'>
            <div className="content">
              {renderContent()}
              {/* <Image src={map} fluid className='mb-4' style={{ width: '100%' }} /> */}
            </div>
          </Col>
          {/* For small screens, menu is on the bottom and content is on the top */}
          <Col xs={12} className='d-block d-lg-none'>
            <div className="content">
              {renderContent()}
              {/* <Image src={map} fluid className='mb-4' style={{ width: '100%' }} /> */}
            </div>
          </Col>
          <Col xs={12} className='d-block d-lg-none'>
            <AboutSideMenu handleMenuItemClick={handleMenuItemClick} activeMenuItem={activeMenuItem} />
          </Col>
        </Row>
      </Container>
    </div>

  );
};

export default About;

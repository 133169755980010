import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/49_2192397239.jpg'

import '../../styles/about.css'

const Research = () => {
    return (
        <div>
            <h5 className='main-title'>Research & Innovation</h5>
            <hr />
            <p className='second-title'>Transforming Ideas into Reality</p>
            <p>At GEMAN POWER, we understand that innovation is a vital component of your business success. That's why we integrate innovative solutions into reality to achieve objectives, such as carbon neutrality by 2050. </p>
            <p className='second-title'>Addressing Dynamic Challenges with Creative Solutions</p>
            <p>Your dynamic challenges require innovative responses, and that's precisely where we excel. We are continually striving to develop fresh approaches and imaginative solutions to meet your technical, operational, and financial objectives.</p>
            <p>At every level of our organization, we prioritize investment in research and development. This commitment enables us to deliver results faster, more efficiently, and at a reduced cost, all without compromising on quality or service standards.</p>
            <p className='second-title'>Energizing Our Focus on Innovation</p>
            <p>To invigorate our commitment to innovation, we continually refine our approach, ensuring that we remain at the forefront of technological advancements and industry trends. With support of Governmental subsidies across the globe, heavily investing in innovative and efficient solutions.</p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default Research
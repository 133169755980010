import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/fieldServices/230_2158048149.jpg';
import man7 from '../../../../images/services/fieldServices/231_1740140642.jpg';
import equipment7 from '../../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site7 from '../../../../images/services/fieldServices/233_1080243251.jpg';
import hse7 from '../../../../images/services/fieldServices/234_383464744.jpg';
import construction7 from '../../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning7 from '../../../../images/services/fieldServices/236_2294645377.jpg';
import '../../../../styles/subItem.css'

const SiteManagement = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };


    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Site Management</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={site7} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER offers comprehensive site management solutions to encompass a wide range of services tailored to meet the unique needs of each project. From overseeing day-to-day operations to ensuring compliance with safety regulations, our experienced team is dedicated to maintaining the efficiency, productivity, and safety of your site. With our proactive approach and attention to detail, you can trust us to effectively manage every aspect of your project, allowing you to focus on achieving your goals.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Field Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/manpower-supply" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Manpower Supply</p>
                                <div className='card-image-frame'>
                                    <Image src={man7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/equipment-tooling-fleet-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Equipment, Tooling & Fleet Services</p>
                                <div className='card-image-frame'>
                                    <Image src={equipment7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/health-safety-env-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>HSE Services </p>
                                <div className='card-image-frame'>
                                    <Image src={hse7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/construction-services-and-execution" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Construction Services and Execution </p>
                                <div className='card-image-frame'>
                                    <Image src={construction7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/commissioning-turnover" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Commissioning & Turnover</p>
                                <div className='card-image-frame'>
                                    <Image src={commissioning7} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>



                </Row>
            </Container>
        </div>
    )
}

export default SiteManagement
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/epc/260_398992222.jpg';

import oilGas3 from '../../../../../images/services/epc/energy/261_1_271820135.jpg';
import oilNatural3 from '../../../../../images/services/epc/energy/261_2_113550355.jpg';
import bioFuel3 from '../../../../../images/services/epc/energy/261_3_1054225235.jpg';
import lng3 from '../../../../../images/services/epc/energy/261_4_1346316614.jpg';
import petro3 from '../../../../../images/services/epc/energy/261_5_549195883.jpg';
import '../../../../../styles/subItem.css'

const Lng = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Liquefied Natural Gas (LNG)</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={lng3} className='subitem-img' />
                        </div>
                        <p>In today's industrial landscape, construction projects are characterized by their unprecedented scale and technical intricacy. GEMAN POWER is at the forefront, providing comprehensive, field-tested solutions that ensure the predictable outcomes demanded by these projects.</p>
                        <p>Leveraging our expertise, we navigate the complexities of LNG projects with precision, ensuring adherence to stringent safety standards and optimal performance outcomes. Trust GEMAN POWER for LNG construction solutions that meet the highest industry standards and exceed expectations.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Energy & Chemicals </h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-gas-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil & Gas Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={oilGas3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-natural-gas-pipelines" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil/Natural Gas Pipelines</p>
                                <div className='card-image-frame'>
                                    <Image src={oilNatural3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/biofuel-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>BioFuel Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={bioFuel3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/petroleum-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Petroleum Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={petro3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Lng
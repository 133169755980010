import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import '../../../../../styles/subItem.css'
import legend from '../../../../../images/services/epc/260_398992222.jpg';

import oilGas6 from '../../../../../images/services/epc/energy/261_1_271820135.jpg';
import oilNatural6 from '../../../../../images/services/epc/energy/261_2_113550355.jpg';
import bioFuel6 from '../../../../../images/services/epc/energy/261_3_1054225235.jpg';
import lng6 from '../../../../../images/services/epc/energy/261_4_1346316614.jpg';
import petro6 from '../../../../../images/services/epc/energy/261_5_549195883.jpg';

const Petroleum = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Petroleum Projects</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={petro6} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER is a trusted partner in the downstream market, specializing in the design and construction of clean fuel production facilities. Our expertise encompasses a diverse range of projects.</p>
                        <p>With a focus on optimization, our technical experts excel in designing, procuring, and constructing distillation, hydroprocessing, FCC (Fluid Catalytic Cracking), coking, octane enhancement, hydrogen production, and sulfur removal units for our clients. Leveraging our extensive experience and innovative solutions, we ensure that each project meets the highest standards of efficiency, safety, and environmental sustainability. Trust GEMAN POWER to deliver exceptional results for your petroleum projects, driving forward the future of clean fuel production.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Energy & Chemicals </h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-gas-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil & Gas Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={oilGas6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-natural-gas-pipelines" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil/Natural Gas Pipelines</p>
                                <div className='card-image-frame'>
                                    <Image src={oilNatural6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/biofuel-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>BioFuel Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={bioFuel6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/lng" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Liquefied Natural Gas</p>
                                <div className='card-image-frame'>
                                    <Image src={lng6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Petroleum
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/epc/260_398992222.jpg';

import oilGas2 from '../../../../../images/services/epc/energy/261_1_271820135.jpg';
import oilNatural2 from '../../../../../images/services/epc/energy/261_2_113550355.jpg';
import bioFuel2 from '../../../../../images/services/epc/energy/261_3_1054225235.jpg';
import lng2 from '../../../../../images/services/epc/energy/261_4_1346316614.jpg';
import petro2 from '../../../../../images/services/epc/energy/261_5_549195883.jpg';
import '../../../../../styles/subItem.css'

const BioFuelPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>BioFuel Power Plant</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={bioFuel2} className='subitem-img' />
                        </div>
                        <p>As energy production and consumption patterns undergo transformation, there is a surge in demand for renewable sources. GEMAN POWER's extensive experience is instrumental in spearheading new and innovative approaches to power generation. With an enhanced understanding of the environmental impact of energy usage and the emergence of economically viable solutions, communities and nations worldwide are increasingly relying on renewable resources to fulfill their energy requirements. From pioneering power generation initiatives to advancing biofuels production, GEMAN POWER boasts decades of expertise in delivering cutting-edge plants and facilities poised to shape the future of energy.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Energy & Chemicals </h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-gas-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil & Gas Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={oilGas2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-natural-gas-pipelines" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil/Natural Gas Pipelines</p>
                                <div className='card-image-frame'>
                                    <Image src={oilNatural2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/lng" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Liquefied Natural Gas</p>
                                <div className='card-image-frame'>
                                    <Image src={lng2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/petroleum-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Petroleum Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={petro2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default BioFuelPower
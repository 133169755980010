import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/fieldServices/230_2158048149.jpg';
import man2 from '../../../../images/services/fieldServices/231_1740140642.jpg';
import equipment2 from '../../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site2 from '../../../../images/services/fieldServices/233_1080243251.jpg';
import hse2 from '../../../../images/services/fieldServices/234_383464744.jpg';
import construction2 from '../../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning2 from '../../../../images/services/fieldServices/236_2294645377.jpg';

import '../../../../styles/subItem.css'

const Commissioning = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Commissioning & Turnover</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={commissioning2} className='subitem-img' />
                        </div>
                        <p>Achieving success as a startup demands a collaborative team effort, with thorough planning and engagement from all stakeholders. Timely completion of a top-tier facility, built with safety and excellence in mind, necessitates early development of a comprehensive commissioning and start-up plan.</p>
                        <p>This plan, grounded in a systems approach, establishes clear system turnover dates to ensure seamless execution and support throughout the process. By adopting this proactive approach, we pave the way for a successful and efficient start-up, setting the stage for long-term success.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Field Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/manpower-supply" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Manpower Supply</p>
                                <div className='card-image-frame'>
                                    <Image src={man2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/equipment-tooling-fleet-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Equipment, Tooling & Fleet Services</p>
                                <div className='card-image-frame'>
                                    <Image src={equipment2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/site-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Site Management</p>
                                <div className='card-image-frame'>
                                    <Image src={site2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/health-safety-env-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>HSE Services </p>
                                <div className='card-image-frame'>
                                    <Image src={hse2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/construction-services-and-execution" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Construction Services and Execution </p>
                                <div className='card-image-frame'>
                                    <Image src={construction2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>




                </Row>
            </Container>
        </div>
    )
}

export default Commissioning
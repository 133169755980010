import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/epc/260_398992222.jpg';

import oilGas from '../../../../../images/services/epc/energy/261_1_271820135.jpg';
import oilNatural from '../../../../../images/services/epc/energy/261_2_113550355.jpg';
import bioFuel from '../../../../../images/services/epc/energy/261_3_1054225235.jpg';
import lng from '../../../../../images/services/epc/energy/261_4_1346316614.jpg';
import petro from '../../../../../images/services/epc/energy/261_5_549195883.jpg';

import '../../../../../styles/mainItem.css'

const AllEnergyChemicals = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Energy & Petroleum Plants</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-gas-power-plant" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Oil & Gas Power Plant</p>
                                    <div className='card-image-frame'>
                                        <Image src={oilGas} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-natural-gas-pipelines" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Oil/Natural Gas Pipelines</p>
                                    <div className='card-image-frame'>
                                        <Image src={oilNatural} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/biofuel-power-plant" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>BioFuel Power Plant</p>
                                    <div className='card-image-frame'>
                                        <Image src={bioFuel} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/lng" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Liquefied Natural Gas</p>
                                    <div className='card-image-frame'>
                                        <Image src={lng} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/petroleum-projects" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Petroleum Projects</p>
                                    <div className='card-image-frame'>
                                        <Image src={petro} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AllEnergyChemicals
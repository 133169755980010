import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/epc/260_398992222.jpg';

import oilGas5 from '../../../../../images/services/epc/energy/261_1_271820135.jpg';
import oilNatural5 from '../../../../../images/services/epc/energy/261_2_113550355.jpg';
import bioFuel5 from '../../../../../images/services/epc/energy/261_3_1054225235.jpg';
import lng5 from '../../../../../images/services/epc/energy/261_4_1346316614.jpg';
import petro5 from '../../../../../images/services/epc/energy/261_5_549195883.jpg';
import '../../../../../styles/subItem.css'

const OilNaturalGasPipelines = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Oil/Natural Gas Pipelines </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={oilNatural5} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER is a globally recognized contractor specializing in the survey, design, consultation, procurement, construction, and management of long-distance pipelines and associated infrastructure, as well as medium-to-large pipeline projects. Our expertise extends to engineering services for pipelines transporting various mediums, including crude oil, natural gas, refined products, LPG, coal gas, nitrogen, oxygen, water, ethylene, coal, and ore slurry. We adhere strictly to both local and international standards in all our operations.</p>
                        <p>Our comprehensive range of services includes engineering survey, design, supervision, and consultation, pipeline construction, pipeline anti-corrosion work and not limited to. With our extensive capabilities and commitment to excellence, GEMAN POWER is dedicated to delivering top-notch solutions that meet the unique needs of our clients while adhering to the highest industry standards.</p>

                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Energy & Chemicals </h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/oil-gas-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Oil & Gas Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={oilGas5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/biofuel-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>BioFuel Power Plant</p>
                                <div className='card-image-frame'>
                                    <Image src={bioFuel5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/lng" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Liquefied Natural Gas</p>
                                <div className='card-image-frame'>
                                    <Image src={lng5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/energy-petroleum-plants/petroleum-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Petroleum Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={petro5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default OilNaturalGasPipelines
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import high2 from '../../../../../images/services/operation/253_2328349621.jpg';
import operation from '../../../../../images/home/22_660549931.jpg';
import '../../../../../styles/subItem.css'

const HighVoltage = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>High Voltage Substation </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={high2} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER Services has ushered in a multitude of benefits and elevated the service experience for our esteemed customers. Our company's most valuable asset lies in the extensive training and experience of our technicians, which collectively empower us to deliver expert assistance in maintaining critical Electrical Distribution Systems for our customers.</p>
                        <p>Whether it's routine maintenance, troubleshooting, or emergency repairs, our team is equipped to provide prompt and effective solutions that meet the unique needs of each customer.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Operation & Maintenance Services </h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Operation & Maintenance Services </p>
                                <div className='card-image-frame'>
                                    <Image src={operation} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HighVoltage
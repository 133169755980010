import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';

import careerImg from '../images/career/51_372232771.jpg';
import emailjs from '@emailjs/browser';

import '../styles/career.css'

const Career = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        position: '',
        message: '',
        placeOfBirth: '',
        dateOfBirth: '',
        gender: '',
        maritalStatus: '',
        licenseClass: '',
        licenseYear: '',
        gpa: '',
        graduationYear: '',
        address: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const formCareer = useRef();

    useEffect(() => {
        emailjs.init('jU6qtxyZcwKWLmVXA'); // Initialize EmailJS with your public key
    }, []); // Empty dependency array ensures it runs only once on component mount

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

        emailjs.sendForm(
            'service_3nc3pag', // service id
            'template_u229xud', // template id
            formCareer.current,
            '2Gwe-yy_r8GiJ6DMc' // public key here
        )
            .then((result) => {
                alert('Thank you ' + formData.name + ' 😊 ' + '!');
                // Smooth scroll to the top of the page
                window.scrollTo(0, 0)
            }, (error) => {
                alert(error.text);
            });

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            phone: '',
            position: '',
            message: '',
            placeOfBirth: '',
            dateOfBirth: '',
            gender: '',
            maritalStatus: '',
            licenseClass: '',
            licenseYear: '',
            gpa: '',
            graduationYear: '',
            address: ''
        });
    };

    return (
        <div className="career-container">
            <Image src={careerImg} fluid className="career-img" />
            <Container>
                <Row className='mt-4'>
                    <Col md={6}>
                        <div className="career-area">
                            <h5 className='main-title'>Career Areas</h5>
                            <p className='second-title'>Join Our Innovative Team</p>
                            <p>
                                As a leading global energy service and engineering company, we specialize in offering comprehensive services and products encompassing innovation, technology, maintenance and construction management across various markets and disciplines. Being part of our team entails collaborating with state-of-the-art technology and embarking on a fulfilling and purposeful career journey.
                            </p>
                            <p>
                                We extend opportunities to candidates at every stage of their professional journey, from students and recent graduates to seasoned professionals.
                            </p>
                            <p>
                                Discover more about us and become a part of our team today!
                            </p>
                        </div>
                        <div className="career-area">
                            <p className='second-title'>Top 10 Reasons To Join Our Team</p>
                            <p>Engaging in meaningful endeavors that enhance the quality of life worldwide.</p>
                            <p>Fostering a distinctive culture where individuals translate their passions into tangible actions.</p>
                            <p>Embracing a comprehensive rewards approach, including competitive compensation, extensive benefits, and bonus potential.</p>
                            <p>Promoting a healthy work-life equilibrium with paid time off and adaptable schedules to effectively balance personal and professional commitments.</p>
                            <p>Dedicating ourselves to continuous learning and professional growth.</p>
                            <p>Actively participating in community initiatives, with employees generously contributing their time, effort, and resources to support local charitable causes.</p>
                            <p>Nurturing a collaborative atmosphere where every voice is valued, and the exchange of best practices is encouraged.</p>
                            <p>Embracing diversity and inclusivity as fundamental principles, as we believe they foster better solutions and outcomes through varied perspectives, fresh ideas, creativity, collaboration, and innovation.</p>
                            <p>Encouraging a culture of adaptability and resilience, enabling us to navigate through challenging economic climates while maintaining our commitment to excellence and innovation.</p>
                            <p>Demonstrating a steadfast commitment to sustainability through our daily business practices, aimed at safeguarding the environment and preserving natural resources.</p>


                        </div>

                    </Col>
                    <Col md={6}>
                        <div className="career-opportunity">
                            <h5 className='main-title'>Human Resources Opportunities</h5>
                            <p>We're always looking for talented individuals to join our team. If you're passionate about technology and innovation, we'd love to hear from you!</p>
                            <Form ref={formCareer} onSubmit={handleSubmit}>
                                <Form.Group controlId="formName" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Name Surname"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={6} className="mb-3">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={6}>
                                        <Form.Control
                                            className="mb-3"
                                            type="text"
                                            placeholder="Place of Birth"
                                            name="placeOfBirth"
                                            value={formData.placeOfBirth}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control
                                            type="date"
                                            name="dateOfBirth"
                                            placeholder='Date of Birth'
                                            value={formData.dateOfBirth}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={6} className="mb-3">
                                        <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange} required>
                                            <option value="">Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </Form.Control>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control as="select" name="maritalStatus" value={formData.maritalStatus} onChange={handleChange} required>
                                            <option value="">Maritial Status</option>
                                            <option value="single">Single</option>
                                            <option value="married">Married</option>
                                            <option value="divorced">Divorced</option>
                                            <option value="widowed">Widowed</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={6} className="mb-3">
                                        <Form.Control as="select" name="licenseClass" value={formData.licenseClass} onChange={handleChange} required>
                                            <option value="">Driving License</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Form.Control>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control type="text" name="licenseYear" placeholder="Driving License Year/Class" value={formData.licenseYear} onChange={handleChange} required />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={6} className="mb-3">
                                        <Form.Control type="number" step="0.01" name="gpa" placeholder="Educational Data" value={formData.gpa} onChange={handleChange} required />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control type="text" name="graduationYear" placeholder="Graduation Year/Department" value={formData.graduationYear} onChange={handleChange} required />
                                    </Col>
                                </Form.Group>

                                <Form.Group controlId="address" className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formMessage" className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Summary Profile"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit" className="bg-black text-white">
                                    Send
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Career;

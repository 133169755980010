import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/46_533118763.jpg'

import '../../styles/about.css'

const VisionMission = () => {
    return (
        <div>
            <h5 className='main-title'>Vision, Mission & Values</h5>
            <hr />
            <p className='second-title'>Our Vision:</p>
            <p>
                At GEMAN POWER, we envision being a catalyst for positive change in our communities and the world, driven by an unwavering commitment to growth, innovation, ethics, and technical excellence.
            </p>
            <p className='second-title'>Our Mission:</p>
            <p>
                Our mission is to astound our clients with unparalleled responsiveness and innovation, earning their trust as their foremost advisor. We aim to cultivate a fulfilling work environment and deliver fair returns on investment.
            </p>
            <p className='second-title'>Our Values:</p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Excellence in Client Service. </span>
                Through Continuous Quality Improvement, we pledge to meet our clients' needs with services that are responsive, innovative, and technically superior. By continually refining our processes, we strive to get it right the first time.
            </p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Valuing Our Employees. </span>
                Recognizing that our employees are our greatest asset, we are dedicated to providing them with a safe, engaging, and rewarding workplace. We foster an atmosphere of trust, offer comprehensive training and resources, and support their professional growth.

            </p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Teamwork. </span>
                We champion collaborative leadership and foster a culture of camaraderie, where each employee's opinion is valued, team goals take precedence over individual needs, and the success of the entire organization supersedes that of any individual unit.
            </p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Sustainability. </span>
                Committed to environmental stewardship, we take proactive measures to minimize our ecological footprint and conserve natural resources in both our operations and projects. Our aim is to meet present needs without compromising the future.
            </p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Professional and Community Engagement. </span>
                We encourage active involvement in professional associations and community service, recognizing the benefits they bring to society, our company, and individual employees.
            </p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Ethical Business Practices. </span>
                Integrity and honesty are foundational to our business. We only undertake projects for which we are qualified, ensure fair compensation for our services, avoid conflicts of interest, and handle confidential information with utmost care.
            </p>
            <p>
                <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Financial Stability. </span>
                We manage our business prudently to ensure long-term financial growth and profitability. This approach enables us to better serve our clients, enhance workforce stability, and contribute to our company's overall success.
            </p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default VisionMission
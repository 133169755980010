import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';
import legend from '../../../images/services/engServices/220_2114019077.jpg';
import tech from '../../../images/services/engServices/221_1887083413.jpg';
import design from '../../../images/services/engServices/222_1504702805.jpg';
import project from '../../../images/services/engServices/223_2293858001.jpg';
import '../../../styles/mainItem.css'

const EngineeringDesign = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Engineering Services</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/engineering-design/technical-support" onClick={handleScrollToTop} >
                                <Card.Body>
                                    <p>Technical Support (24/7)</p>
                                    <div className='card-image-frame'>
                                        <Image src={tech} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/engineering-design/design-manufacturing" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Design & Manufacturing</p>
                                    <div className='card-image-frame'>
                                        <Image src={design} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/engineering-design/project-program-management" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Project & Program Management </p>
                                    <div className='card-image-frame'>
                                        <Image src={project} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default EngineeringDesign
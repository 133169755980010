import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import asset from '../images/asset-management.jpeg';

import '../styles/mainItem.css'

const MainItem = () => {
    return (
        <div className='main-page'>
            <Image src={asset} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1>Main Item</h1>
                    <Row className='justify-content-md-center'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Manpower Field Services</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Engineering Services</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Operations & Maintenance Services</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>EPC Services</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Supply Chain Services</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Renewable Power Plants</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Hydrogen Production & Storage</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/subItem">
                                <Card.Body>
                                    <p>Energy Storage (R&D)</p>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default MainItem
import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/45_1134461030.jpg'

import '../../styles/about.css'

const Quality = () => {
  return (
    <div>
      <h5 className='main-title'>Quality</h5>
      <hr />
      <p>Quality is the cornerstone of GEMAN POWER's philosophy, driving our commitment to delivering exceptional solutions tailored to our clients' needs. We meticulously scrutinize every aspect of our work, ensuring excellence from preconstruction to project completion.</p>
      <p>For us, "Quality" means more than just meeting client requirements; it's about surpassing expectations and achieving critical project objectives. Our dedication to Continuous Quality Improvement is the bedrock of our quality program, empowering employees to innovate and enhance efficiency. Independently audited, our quality management process consistently delivers responsive, technically sound services that meet our clients' needs.</p>
      <p>Our Quality Management System (QMS) governs every project, adhering to the industry-standard, and certified to the ISO 9001:2015. Internal and external audits ensure companywide compliance, while client feedback surveys consistently validate our success, with satisfaction ratings exceeding 94%.</p>
      <p>At GEMAN POWER, everyone is committed to Continuous Quality Improvement, contributing to our mission of astounding clients with responsiveness and innovation. That’s how we become their trusted advisor.</p>
      <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
    </div>
  )
}

export default Quality
import React from 'react'
import { Image } from 'react-bootstrap';
import about from '../../images/about/44_701690509.jpg'

import '../../styles/about.css'

const History = () => {
    return (
        <div>
            <h5 className='main-title'>History</h5>
            <hr />
            <p>At GEMAN POWER, we take immense pride in our heritage of experience and the transformation we've undergone to become the company we are today. Throughout our history, it's been the unwavering dedication that has propelled us to the forefront of global engineering and service company, pioneering innovative solutions every step of the way.</p>
            <p>Our journey is rich in product innovation and services. We diligently listen to our customers, and we tailor-make experiences that exceed expectations. This approach fuels our strategic growth. From modest origins as a small entity, GEMAN POWER has ascended to a prominent position as a global corporation.</p>
            <Image src={about} fluid className='mb-4' style={{ width: '100%' }} />
        </div>
    )
}

export default History
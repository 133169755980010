import React, { useEffect, useState } from 'react'
import { Nav, Navbar, NavDropdown, Container, Row, Col, Image, Form } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import logo from '../images/noBg.png';
import asset from '../images/asset-management.jpeg';
import search from '../images/icons/search.svg';

import consulting from '../images/home/18_1108431911.jpg';
import engService from '../images/home/19_2114019077.jpg';
import field from '../images/home/20_2158048149.jpg';
import supply from '../images/home/21_2151657351.jpg';
import operation from '../images/home/22_660549931.jpg';
import epc from '../images/home/23_398992222.jpg';
import hydrogen from '../images/home/24_2168999393.jpg';
import energy from '../images/home/25_2084987944.jpg';

import icon1 from '../images/icons/icon-1.svg';
import icon2 from '../images/icons/icon-2.svg';
import icon3 from '../images/icons/icon-3.svg';
import icon4 from '../images/icons/icon-4.svg';
import icon5 from '../images/icons/icon-5.svg';
import icon6 from '../images/icons/icon-6.svg';
import icon7 from '../images/icons/icon-7.svg';
import icon8 from '../images/icons/icon-8.svg';
import icon9 from '../images/icons/icon-9.svg';
import icon10 from '../images/icons/icon-10.svg';
import flag from '../images/icons/usa-flag.png';

import '../styles/myHeader.css'

const MyHeader = () => {
    const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const [productsDropdownOpen, setProductsDropdownOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);

    console.log('services', servicesDropdownOpen)
    console.log('products', productsDropdownOpen)
    const handleServicesItemClick = () => {
        setServicesDropdownOpen(false); // Close the dropdown when a menu item is clicked

        window.scrollTo(0, 0)
    };

    useEffect(() => {
        if (servicesDropdownOpen === true) {
            setProductsDropdownOpen(false);
        }
    }, [servicesDropdownOpen])

    useEffect(() => {
        if (productsDropdownOpen === true) {
            setServicesDropdownOpen(false);
        }
    }, [productsDropdownOpen])

    const handleProductsItemClick = () => {
        setProductsDropdownOpen(false); // Close the dropdown when a menu item is clicked

        window.scrollTo(0, 0)
    };




    const handleMenuItemClick = () => {
        setExpanded(false);
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };


    return (
        <div>
            <div className='header-line'>
                <Container>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col xs={1}><Image src={icon1} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon2} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon3} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon4} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon5} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon6} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon7} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon8} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon9} className='header-icon' /></Col>
                        <Col xs={1}><Image src={icon10} className='header-icon' /></Col>
                        <Col xs={2} className='d-flex justify-content-end'>
                            <Image src={flag} className='flag-icon' />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Navbar expand="lg" fixed='top' className="navbar-background" expanded={expanded}>
                <Container>
                    <Navbar.Brand onClick={handleMenuItemClick}>
                        <Link to="/">
                            <img src={logo} className='logo' alt="logo" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" onClick={() => setExpanded(expanded ? false : "expanded")} />
                    <Navbar.Collapse id="offcanvasNavbar-expand-lg">
                        <Nav className="me-auto ">
                            <Nav.Link as={Link} to="/" onClick={handleMenuItemClick} className='menu-items2'>Home</Nav.Link>

                            <NavDropdown
                                title="Services"
                                id="basic-nav-dropdown"
                                className="menu-items2"
                                show={servicesDropdownOpen}
                                onClick={() => setServicesDropdownOpen(!servicesDropdownOpen)}
                            >
                                <Container className='menuItems'>
                                    <Row >
                                        <Col sm={6} md={4} className='d-flex justify-content-center align-items-center'>
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleServicesItemClick}>
                                                    <Link to="/consulting" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={consulting} alt="Image 1" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Consulting</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col sm={6} md={4} className='d-flex justify-content-center align-items-center'>
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleServicesItemClick}>
                                                    <Link to="/engineering-design" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={engService} alt="Image 2" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Engineering Services</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col sm={6} md={4} className='d-flex justify-content-center align-items-center'>
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleServicesItemClick}>
                                                    <Link to="/field-services" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={field} alt="Image 3" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Field Services</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} md={4} className='d-flex justify-content-center text-center align-items-center'>
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleServicesItemClick}>
                                                    <Link to="/supply-chain-services" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={supply} alt="Image 5" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Supply Chain Services</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col sm={6} md={4} className='d-flex justify-content-center text-center align-items-center'>
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleServicesItemClick}>
                                                    <Link to="/operation-maintenance-services" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={operation} alt="Image 6" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Operations & Maintenance Services</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col sm={6} md={4} className='d-flex justify-content-center text-center align-items-center mt-4'>
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleServicesItemClick}>
                                                    <Link to="/epc-services" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={epc} alt="Image 4" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>EPC Services</h5>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            </NavDropdown>
                            <NavDropdown
                                title="Products"
                                id="basic-nav-dropdown"
                                className="menu-items2"
                                show={productsDropdownOpen}
                                onClick={() => setProductsDropdownOpen(!productsDropdownOpen)}
                            >
                                <Container className='menuItems'>
                                    <Row>
                                        <Col className="d-flex justify-content-center align-items-center text-center ">
                                            <ul className="menu-list">
                                                <li className="menu-item" onClick={handleProductsItemClick}>
                                                    <Link to="/products/hydrogen/index" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={hydrogen} alt="Image 1" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Hydrogen Production & Storage</h5>
                                                    </Link>
                                                    {/* <div className='d-flex justify-content-center'>
                                                        <Image src={products1} alt="Image 1" fluid className='dropdown-image' />
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <h5>
                                                            <Link to="/products/hydrogen/index" className='mainTitleMenu' onClick={() => setExpanded(false)}>Hydrogen Production & Storage</Link>
                                                        </h5>
                                                    </div> */}
                                                </li>
                                                <li className="menu-item" onClick={handleProductsItemClick}>
                                                    <Link to="/products/energy/index" className='mainTitleMenu' onClick={() => setExpanded(false)}>
                                                        <Image src={energy} alt="Image 2" fluid className='dropdown-image' />
                                                        <h5 style={{ fontWeight: 'bold' }}>Energy Storage</h5>
                                                    </Link>
                                                    {/* <div className='d-flex justify-content-center'>
                                                        <Image src={products2} alt="Image 2" fluid className='dropdown-image' />
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <h5>
                                                            <Link to="/products/energy/index" className='mainTitleMenu' onClick={() => setExpanded(false)}>Energy Storage</Link>
                                                        </h5>
                                                    </div> */}
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            </NavDropdown>
                            <Nav.Link as={Link} to="/about" onClick={handleMenuItemClick} className='menu-items2'>About</Nav.Link>
                            <Nav.Link as={Link} to="/career" onClick={handleMenuItemClick} className='menu-items2'>Career</Nav.Link>
                            <Nav.Link as={Link} to="/contact" onClick={handleMenuItemClick} className='menu-items2'>Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>


                </Container>
            </Navbar>
        </div>
    )
}

export default MyHeader
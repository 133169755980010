import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import logo from '../images/geman-power.svg';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import asset from '../images/asset-management.jpeg';

import netCarbon from '../images/home/10_2311223577 Hydrogen Fueling the Future_s Energy Transition.jpg';
import assetManagement from '../images/home/15_1700360854.jpg';
import energyTransition from '../images/home/16_1939789771.jpg';
import proudToBe from '../images/home/17_2290889285.jpg';
import co2 from '../images/home/26_1921763675.jpg';

// import energy from '../images/energy-transition.jpeg';

import Carousel from "react-bootstrap/Carousel";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

import Card from 'react-bootstrap/Card';
import ServicesProducts from '../components/Home/ServicesProducts';

// import icon1 from '../images/icons/icon-1.svg';
// import icon2 from '../images/icons/icon-2.svg';
// import icon3 from '../images/icons/icon-3.svg';
// import icon4 from '../images/icons/icon-4.svg';
// import icon5 from '../images/icons/icon-5.svg';
// import icon6 from '../images/icons/icon-6.svg';
// import icon7 from '../images/icons/icon-7.svg';
// import icon8 from '../images/icons/icon-8.svg';
// import icon9 from '../images/icons/icon-9.svg';
// import icon10 from '../images/icons/icon-10.svg';
// import icon11 from '../images/icons/icon-11.svg';
// import icon12 from '../images/icons/icon-12.svg';

import commentMark from '../images/comment-mark.png';

import iso1 from '../images/iso1.png';
import iso2 from '../images/iso2.png';
import iso3 from '../images/iso3.png';
import iso4 from '../images/iso4.png';
import iso5 from '../images/iso5.png';

import '../styles/home.css'

const Home = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='home-page'>
            <div className='logo-container'></div>
            <div>
                <Carousel variant="white" className="container-carousel2" fade>
                    <Carousel.Item interval={2500}>
                        <div className="d-block1">
                            <h1>Hydrogen: <span style={{ display: 'block' }}>Fueling the Future's Energy Transition</span></h1>
                        </div>
                        <Carousel.Caption className="carosuel-content"></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <div className="d-block2">
                            <h1>Empowering the Future: <span style={{ display: 'block' }}>Harnessing Gas Power for Sustainable Progress</span></h1>
                        </div>
                        <Carousel.Caption className="carosuel-content"></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <div className="d-block3">
                            <h1>Energizing Tomorrow: <span style={{ display: 'block' }}>Championing Renewable Power Today</span></h1>
                        </div>
                        <Carousel.Caption className="carosuel-content"></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <div className="d-block4">
                            <h1>Trust Us with Your Assets: <span style={{ display: 'block' }}>Your Partner for Progress and Growth</span></h1>
                        </div>
                        <Carousel.Caption className="carosuel-content"></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <div className="d-block5">
                            <h1>Unlocking Energy Potential: <span style={{ display: 'block' }}>Partner with Us for a Transformed Future</span></h1>
                        </div>
                        <Carousel.Caption className="carosuel-content"></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='exec-summary'>
                <Container>
                    <Row>
                        <div className='definition-title'>
                            Providing energy solutions for all.
                        </div>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className='my-column'>
                            <div class="card-hover">
                                <div class="card-hover__content">
                                    <Link to="/services" onClick={handleScrollToTop} className="card-hover__title">
                                        <div>
                                            <span>Asset Management</span>
                                        </div>
                                    </Link>
                                    {/* <h3 class="card-hover__title">
                                        Asset Management
                                    </h3> */}
                                    {/* mobil icin kontrol et */}
                                    <p class="card-hover__text">
                                        Maintaining and integrating current assets into clean and sustainable power generation.
                                    </p>
                                    {/* <p class="card-hover__text">
                                        into clean and sustainable power generation.
                                    </p> */}
                                    {/* <Link to="/services" onClick={handleScrollToTop} className="card-hover__link">
                                        <div>
                                            <span>Explore</span>
                                        </div>
                                    </Link> */}
                                </div>
                                <Image src={assetManagement} rounded />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className='my-column'>
                            <div class="card-hover">
                                <div class="card-hover__content">
                                    <Link to="/products" onClick={handleScrollToTop} className="card-hover__title">
                                        <div>
                                            <span>Energy Transition</span>
                                        </div>
                                    </Link>
                                    {/* <h3 class="card-hover__title">
                                        Energy Transition
                                    </h3> */}
                                    <p class="card-hover__text">
                                        Developing cutting-edge technologies and solutions to pave the way for a zero-carbon future.
                                    </p>
                                    {/* <p class="card-hover__text">
                                        solutions to pave the way for a zero-carbon future.
                                    </p> */}
                                    {/* <Link to="/products" onClick={handleScrollToTop} className="card-hover__link">
                                        <div>
                                            <span>Explore</span>
                                        </div>
                                    </Link> */}
                                </div>
                                <Image src={energyTransition} rounded />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='definition-title'>
                We are committed to delivering excellence!
            </div>

            <div className='proud'>
                <img src={proudToBe} className="proud-image"></img>
                <h1 className='proud-text'>Proud to be a part of a growing and future-forward community</h1>
            </div>

            <div className='definition-title'>
                The journey towards Clean Energy future...
            </div>
            <div className='definition-text'>
                GEMAN POWER's vision is to serve customers by both maintaining their existing assets and providing solutions for transitioning power production from conventional to renewable energy sources, towards a cleaner energy future.
            </div>
            <div className='sub-items'>
                <ServicesProducts />
            </div>
            <div className='definition-title'>
                We are dedicated to contributing to clean and sustainable energy!
            </div>
            <div className='definition-text'>
                From energy production to storage, from transmission to utilization, our expertise enables us to provide solutions that align with governmental law and legislations.
            </div>
            <div className='twoZeroFiveZero'>
                2050
            </div>

            <div className='definition-text'>
                The target of achieving net-zero Carbon neutrality.
            </div>
            <div className='definition-text' style={{ fontSize: '3vw' }}>
                Hydrogen is the future!
            </div>
            <div className='definition-text'>
                With support of Governmental subsidies across the globe, we are heavily investing in innovative and efficient solutions with production of Hydrogen and storage.
            </div>

            <div className='proud'>
                <img src={co2} className="proud-image"></img>
            </div>

            <div className='definition-text'>
                Energy storage, particularly of excess energy from renewable sources, provides a mobile and instant power source for various processes, including Hydrogen production, among others.
            </div>

            <div className='definition-title'>
                Join us on the journey towards a greener future!
            </div>
            <div className='definition-text'>
                Unlocking the power of seamless energy solutions, we are your ultimate partner with our innovative services and products in meeting and exceeding diverse energy needs.
            </div>



            {/* <div>
                <Container>
                    <Row className='justify-content-md-center mt-4 mb-4'>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <Card className='sub-items-card'>
                                <Card.Body>
                                    <img src={iso1} className='iso-images' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <Card className='sub-items-card'>
                                <Card.Body>
                                    <img src={iso2} className='iso-images' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <Card className='sub-items-card'>
                                <Card.Body>
                                    <img src={iso3} className='iso-images' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <Card className='sub-items-card'>
                                <Card.Body>
                                    <img src={iso4} className='iso-images' />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                            <Card className='sub-items-card'>
                                <Card.Body>
                                    <img src={iso5} className='iso-images' />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div> */}


        </div>
    )
}

export default Home
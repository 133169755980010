import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/consulting/210_1108431911.jpg';
import power5 from '../../../../images/services/consulting/211_271820135.jpg';
import industrial5 from '../../../../images/services/consulting/212_679978150.jpg';
import naturalGas5 from '../../../../images/services/consulting/213_1922037830.jpg';
import airAndGas5 from '../../../../images/services/consulting/214_365136905.jpg';
import highPower5 from '../../../../images/services/consulting/215_1971671024.jpg';
import epcm5 from '../../../../images/services/consulting/216_609092369.jpg';
import '../../../../styles/subItem.css'

const AirAndGas = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Air and Gas Insulate Substation </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={airAndGas5} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER offers comprehensive electrical and civil design solutions for substations, encompassing both gas-insulated (GIS) and conventional air-insulated (AIS) configurations. Our expertise extends to all common switching arrangements, accommodating voltages up to 230kV, including radial bus, main and transfer bus, double bus, ring bus, and breaker-and-one-half setups.</p>
                        <p>We specialize in the design of new substations, as well as upgrades and equipment change-outs for existing stations. Our services cover a wide range of aspects, including controls and protection system design, site development, drainage design, foundation design, support structure design, bus and equipment layout design, conduit layout, and grounding system design. Throughout the design process, we maintain close collaboration with the project owner to ensure alignment with their requirements and objectives.</p>
                        <p>In addition to providing comprehensive design and bid documents, we offer construction support services upon request. This includes activities such as shop drawing reviews and witnessing factory and field tests through project commissioning. Moreover, our experience encompasses the development of bidding documents for alternative project delivery methods, such as the EPC ("Engineer, Procure, Construct") approach.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Consulting</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={power5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/industrial-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Industrial Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={industrial5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/natural-gas-pipeline-construction" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Natural Gas Pipeline Construction</p>
                                <div className='card-image-frame'>
                                    <Image src={naturalGas5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/high-power-energy" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>High Power Energy Transmission Overhead Line</p>
                                <div className='card-image-frame'>
                                    <Image src={highPower5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/epcm" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>EPCM</p>
                                <div className='card-image-frame'>
                                    <Image src={epcm5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default AirAndGas
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/consulting/210_1108431911.jpg';
import power6 from '../../../../images/services/consulting/211_271820135.jpg';
import industrial6 from '../../../../images/services/consulting/212_679978150.jpg';
import naturalGas6 from '../../../../images/services/consulting/213_1922037830.jpg';
import airAndGas6 from '../../../../images/services/consulting/214_365136905.jpg';
import highPower6 from '../../../../images/services/consulting/215_1971671024.jpg';
import epcm6 from '../../../../images/services/consulting/216_609092369.jpg';

import '../../../../styles/subItem.css'

const HighPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2 className='bold-text'>High Power Energy Transmission Overhead Line</h2>
                        <div className='image-frame'>
                            <Image src={highPower6} className='subitem-img' />
                        </div>
                        <p>Effective, resilient, and accessible power transmission and distribution systems play a vital role in addressing the increasing global need for electricity. These systems serve as essential lifelines, extending their reach to both rural and urban regions and delivering significant socioeconomic advantages to communities worldwide.</p>
                        <p>Our company's specialists possess deep expertise and hands-on experience in the design of power transmission lines and electrical substations. Moreover, we excel in overseeing construction projects, ensuring seamless installation of overhead transmission lines and underground power cables. Additionally, GEMAN POWER offers maintenance consultation services, ensuring the continued reliability and efficiency of power infrastructure.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Consulting</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={power6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/industrial-projects" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Industrial Projects</p>
                                <div className='card-image-frame'>
                                    <Image src={industrial6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/natural-gas-pipeline-construction" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Natural Gas Pipeline Construction</p>
                                <div className='card-image-frame'>
                                    <Image src={naturalGas6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/air-and-gas" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Air and Gas insulate Substation</p>
                                <div className='card-image-frame'>
                                    <Image src={airAndGas6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting/epcm" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>EPCM</p>
                                <div className='card-image-frame'>
                                    <Image src={epcm6} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default HighPower
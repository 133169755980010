import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/supply/240_2151657351.jpg';
import supplyOfPlant4 from '../../../../images/services/supply/241_1_3398501.jpg';
import proc4 from '../../../../images/services/supply/242_2439547217.jpg';
import supplier4 from '../../../../images/services/supply/243_1962667825.jpg';
import '../../../../styles/subItem.css'

const Supplier = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Supplier and Contractor Registry Framework Services</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={supplier4} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER invites suppliers, contractors, and other organizations interested in collaborating with us to join our supplier registry. By reaching out to us, you can express your interest and provide updated information about your company. This ensures that GEMAN POWER has the latest details about your services and how they align with our global framework of value-added offerings. We encourage you to create or update your company profile and share how your services complement the diverse range of solutions provided by GEMAN POWER.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Supply Chain Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services/supply-of-plant-equipment" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Supply of Plant Equipment, Systems and Capital Parts </p>
                                <div className='card-image-frame'>
                                    <Image src={supplyOfPlant4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services/procurement-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Procurement Services for Material and Consumable Parts </p>
                                <div className='card-image-frame'>
                                    <Image src={proc4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Supplier
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/fieldServices/230_2158048149.jpg';
import man5 from '../../../../images/services/fieldServices/231_1740140642.jpg';
import equipment5 from '../../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site5 from '../../../../images/services/fieldServices/233_1080243251.jpg';
import hse5 from '../../../../images/services/fieldServices/234_383464744.jpg';
import construction5 from '../../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning5 from '../../../../images/services/fieldServices/236_2294645377.jpg';
import '../../../../styles/subItem.css'

const HealthSafety = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>HSE Services </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={hse5} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER is committed to upholding the highest standards of Health, Safety, and Environment (HSE) across all its operations. Our goal is not only to ensure the well-being of our employees, shareholders, subcontractors, and society but also to promote a culture of responsibility and sustainability.</p>
                        <p>By prioritizing HSE practices, we strive to create a safe and healthy work environment, minimize environmental impact, and contribute positively to the communities in which we operate.</p>
                        <p>Through ongoing training, rigorous adherence to regulations, and continuous improvement initiatives, we aim to manage our operations effectively and responsibly, safeguarding both people and planet for the present and future generations.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Field Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/manpower-supply" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Manpower Supply</p>
                                <div className='card-image-frame'>
                                    <Image src={man5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/equipment-tooling-fleet-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Equipment, Tooling & Fleet Services</p>
                                <div className='card-image-frame'>
                                    <Image src={equipment5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/site-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Site Management</p>
                                <div className='card-image-frame'>
                                    <Image src={site5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/construction-services-and-execution" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Construction Services and Execution </p>
                                <div className='card-image-frame'>
                                    <Image src={construction5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/commissioning-turnover" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Commissioning & Turnover</p>
                                <div className='card-image-frame'>
                                    <Image src={commissioning5} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default HealthSafety
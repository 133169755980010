import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import solar3 from '../../../../../images/services/operation/252_1_736731844.jpg';
import wind3 from '../../../../../images/services/operation/252_2_2137635999.jpg';
import hydro3 from '../../../../../images/services/operation/252_3_475746370.jpg';
import geo3 from '../../../../../images/services/operation/252_4_725616556.jpg';
import '../../../../../styles/subItem.css'

const HydElectricPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2> Hydroelectric Power Plants </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={hydro3} className='subitem-img' />
                        </div>
                        <p>Our maintenance service for hydroelectric facilities encompasses predictive maintenance of electric production centers, as well as maintenance of hydro turbines and electromechanical components.</p>
                        <p>Our aim is to offer customers a comprehensive range of services aimed at maximizing the availability and profitability of their hydro installations.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Renewable Power Plants</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/solar-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Solar Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={solar3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/wind-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Wind Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={wind3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/geothermal-power" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Geothermal Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={geo3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>
        </div>
    )
}

export default HydElectricPower
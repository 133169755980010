import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../images/asset-management.jpeg';
import '../styles/subItem.css'

const SubItem = () => {
    return (
        <div className='sub-page'>
            <Image src={asset} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Sub item</h2>
                        <Image src={asset} className='subitem-img' />
                        <p>One-stop service provider for HDGT, Aero, ST, GEN towards your productivity and profitability goals.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-center mt-3'>
                    <h2 className='mb-4'>Main item</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/mainItem">
                            <Card.Body>
                                <p>Sub Item 1</p>
                                <Image src={asset} rounded className='exec-summary-images2' />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/mainItem">
                            <Card.Body>
                                <p>Sub Item 2</p>
                                <Image src={asset} rounded className='exec-summary-images2' />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/mainItem">
                            <Card.Body>
                                <p>Sub Item 3</p>
                                <Image src={asset} rounded className='exec-summary-images2' />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/mainItem">
                            <Card.Body>
                                <p>Sub Item 4</p>
                                <Image src={asset} rounded className='exec-summary-images2' />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/mainItem">
                            <Card.Body>
                                <p>Sub Item 5</p>
                                <Image src={asset} rounded className='exec-summary-images2' />
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default SubItem
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image, ListGroup } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/epc/260_398992222.jpg';
import solar from '../../../../../images/services/epc/renewable/262_1_1728639280.jpg';
import wind from '../../../../../images/services/epc/renewable/262_2_1564602832.jpg';
import '../../../../../styles/subItem.css'

const WindPowerPlant = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Wind Power Plant</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={wind} className='subitem-img' />
                        </div>
                        <p>Our success in utility-scale wind farm construction is rooted in our unwavering commitment to efficiency, responsiveness to client needs, and adherence to rigorous project schedules. At GEMAN POWER Energy, we prioritize safety, quality, and client satisfaction at every stage of the process.</p>
                        <p>Our comprehensive wind capabilities include Pre-Construction, Engineering and Design, Material Procurement, Construction, Testing and Commissioning.</p>
                        <p>With GEMAN POWER Energy at your side, you can trust in our expertise to deliver exceptional results in wind power plant construction, meeting and exceeding your expectations every step of the way.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Renewable Power Plants</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services/renewable-power-plants/solar-power-plant" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Solar Power Plant </p>
                                <div className='card-image-frame'>
                                    <Image src={solar} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WindPowerPlant
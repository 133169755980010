import React from 'react'
import { Routes, Route } from "react-router-dom";

import MyHeader from './components/MyHeader';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Career from './pages/Career';
import AssetManagement from './pages/AssetManagement';
import EnergyTransition from './pages/EnergyTransition';
import MainItem from './pages/MainItem';
import SubItem from './pages/SubItem';
import MyFooter from './components/MyFooter';

import './App.css';

import Hydrogen from './pages/mainItems/products/Hydrogen';
import SubHydrogen from './pages/subItems/products/SubHydrogen';
import Energy from './pages/mainItems/products/Energy';
import SubEnergy from './pages/subItems/products/SubEnergy';
import Consulting from './pages/mainItems/services/Consulting';
import PowerPlants from './pages/subItems/services/consulting/PowerPlants';
import IndustrialProjects from './pages/subItems/services/consulting/IndustrialProjects';
import NaturalGas from './pages/subItems/services/consulting/NaturalGas';
import AirAndGas from './pages/subItems/services/consulting/AirAndGas';
import HighPower from './pages/subItems/services/consulting/HighPower';
import Epcm from './pages/subItems/services/consulting/Epcm';
import EngineeringDesign from './pages/mainItems/services/EngineeringDesign';
import ProjectProgram from './pages/subItems/services/engineeringDesign/ProjectProgram';
import EngineeringReverse from './pages/subItems/services/engineeringDesign/EngineeringReverse';
import FieldServices from './pages/mainItems/services/FieldServices';
import Manpower from './pages/subItems/services/fieldServices/Manpower';
import Equipment from './pages/subItems/services/fieldServices/Equipment';
import SiteManagement from './pages/subItems/services/fieldServices/SiteManagement';
import HealthSafety from './pages/subItems/services/fieldServices/HealthSafety';
import ConstructionServices from './pages/subItems/services/fieldServices/ConstructionServices';
import Commissioning from './pages/subItems/services/fieldServices/Commissioning';
import SupplyChainServices from './pages/mainItems/services/SupplyChainServices';
import SupplyOfPlant from './pages/subItems/services/supplyChainServices/SupplyOfPlant';
import Procurement from './pages/subItems/services/supplyChainServices/Procurement';
import Supplier from './pages/subItems/services/supplyChainServices/Supplier';
import OMServices from './pages/mainItems/services/OMServices';
import NaturalGasPower from './pages/subItems/services/omServices/powerPlants/NaturalGasPower';
import AllPower from './pages/subItems/services/omServices/powerPlants/AllPower';
import CoalFired from './pages/subItems/services/omServices/powerPlants/CoalFired';
import DieselFired from './pages/subItems/services/omServices/powerPlants/DieselFired';
import AllRenewable from './pages/subItems/services/omServices/renewablePowerPlants/AllRenewable';
import SolarPower from './pages/subItems/services/omServices/renewablePowerPlants/SolarPower';
import WindPower from './pages/subItems/services/omServices/renewablePowerPlants/WindPower';
import HydElectricPower from './pages/subItems/services/omServices/renewablePowerPlants/HydElectricPower';
import GeothermalPower from './pages/subItems/services/omServices/renewablePowerPlants/GeothermalPower';
import AllSubstations from './pages/subItems/services/omServices/substations/AllSubstations';
import HighVoltage from './pages/subItems/services/omServices/substations/HighVoltage';
import EpcServices from './pages/mainItems/services/EpcServices';
import OilGasPower from './pages/subItems/services/epcServices/energyChemicals/OilGasPower';
import AllEnergyChemicals from './pages/subItems/services/epcServices/energyChemicals/AllEnergyChemicals';
import OilNaturalGasPipelines from './pages/subItems/services/epcServices/energyChemicals/OilNaturalGasPipelines';
import BioFuelPower from './pages/subItems/services/epcServices/energyChemicals/BioFuelPower';
import Lng from './pages/subItems/services/epcServices/energyChemicals/Lng';
import Petroleum from './pages/subItems/services/epcServices/energyChemicals/Petroleum';
import SolarPowerPlant from './pages/subItems/services/epcServices/renewablePowerPlants/SolarPowerPlant';
import AllRenewablePower from './pages/subItems/services/epcServices/renewablePowerPlants/AllRenewablePower';
import WindPowerPlant from './pages/subItems/services/epcServices/renewablePowerPlants/WindPowerPlant';
import TechnicalSupport from './pages/subItems/services/engineeringDesign/TechnicalSupport';

function App() {
  return (
    <div className="App">
      <MyHeader />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<About />} path="/about" />
        <Route element={<Career />} path="/career" />
        <Route element={<AssetManagement />} path="/services" />
        <Route element={<EnergyTransition />} path="/products" />

        <Route element={<MainItem />} path="/mainItem" />
        {/* Services Main Items */}
        {/* Consulting */}
        <Route element={<Consulting />} path="/consulting" />
        {/* Engineering Design */}
        <Route element={<EngineeringDesign />} path="/engineering-design" />
        {/* Field Services */}
        <Route element={<FieldServices />} path="/field-services" />
        {/* Supply Chain Services */}
        <Route element={<SupplyChainServices />} path="/supply-chain-services" />
        {/* Operation & Maintenance Services */}
        <Route element={<OMServices />} path="/operation-maintenance-services" />
        {/* EPC Services */}
        <Route element={<EpcServices />} path="/epc-services" />
        {/* Products Main Items */}
        <Route element={<Hydrogen />} path="/products/hydrogen" />
        <Route element={<Energy />} path="/products/energy" />

        <Route element={<SubItem />} path="/subItem" />
        {/* Services Sub Items */}
        {/* Consulting  */}
        <Route element={<PowerPlants />} path="/consulting/power-plants" />
        <Route element={<IndustrialProjects />} path="/consulting/industrial-projects" />
        <Route element={<NaturalGas />} path="/consulting/natural-gas-pipeline-construction" />
        <Route element={<AirAndGas />} path="/consulting/air-and-gas" />
        <Route element={<HighPower />} path="/consulting/high-power-energy" />
        <Route element={<Epcm />} path="/consulting/epcm" />
        {/* Engineering Services */}
        <Route element={<ProjectProgram />} path="/engineering-design/project-program-management" />
        <Route element={<EngineeringReverse />} path="/engineering-design/design-manufacturing" />
        <Route element={<TechnicalSupport />} path="/engineering-design/technical-support" />
        {/* Field Services */}
        <Route element={<Manpower />} path="/field-services/manpower-supply" />
        <Route element={<Equipment />} path="/field-services/equipment-tooling-fleet-services" />
        <Route element={<SiteManagement />} path="/field-services/site-management" />
        <Route element={<HealthSafety />} path="/field-services/health-safety-env-services" />
        <Route element={<ConstructionServices />} path="/field-services/construction-services-and-execution" />
        <Route element={<Commissioning />} path="/field-services/commissioning-turnover" />
        {/* Supply Chain Services */}
        <Route element={<SupplyOfPlant />} path="/supply-chain-services/supply-of-plant-equipment" />
        <Route element={<Procurement />} path="/supply-chain-services/procurement-services" />
        <Route element={<Supplier />} path="/supply-chain-services/supplier-contractor-registry" />
        {/* Operation & Maintenance Services */}
        <Route element={<AllPower />} path="/operation-maintenance-services/power-plants" />
        <Route element={<NaturalGasPower />} path="/operation-maintenance-services/power-plants/natural-gas-power-plants" />
        <Route element={<CoalFired />} path="/operation-maintenance-services/power-plants/coal-fired" />
        <Route element={<DieselFired />} path="/operation-maintenance-services/power-plants/diesel-fired" />

        <Route element={<AllRenewable />} path="/operation-maintenance-services/renewable-power-plants" />
        <Route element={<SolarPower />} path="/operation-maintenance-services/renewable-power-plants/solar-power-plants" />
        <Route element={<WindPower />} path="/operation-maintenance-services/renewable-power-plants/wind-power-plants" />
        <Route element={<HydElectricPower />} path="/operation-maintenance-services/renewable-power-plants/hydroelectric-power-plants" />
        <Route element={<GeothermalPower />} path="/operation-maintenance-services/renewable-power-plants/geothermal-power" />
        <Route element={<AllSubstations />} path="/operation-maintenance-services/substations" />
        <Route element={<HighVoltage />} path="/operation-maintenance-services/substations/high-voltage" />
        {/* EPC Services */}
        <Route element={<AllEnergyChemicals />} path="/epc-services/energy-petroleum-plants" />
        <Route element={<OilGasPower />} path="/epc-services/energy-petroleum-plants/oil-gas-power-plant" />
        <Route element={<OilNaturalGasPipelines />} path="/epc-services/energy-petroleum-plants/oil-natural-gas-pipelines" />
        <Route element={<BioFuelPower />} path="/epc-services/energy-petroleum-plants/biofuel-power-plant" />
        <Route element={<Lng />} path="/epc-services/energy-petroleum-plants/lng" />
        <Route element={<Petroleum />} path="/epc-services/energy-petroleum-plants/petroleum-projects" />
       
        <Route element={<AllRenewablePower />} path="/epc-services/renewable-power-plants" />
        <Route element={<SolarPowerPlant />} path="/epc-services/renewable-power-plants/solar-power-plant" />
        <Route element={<WindPowerPlant />} path="/epc-services/renewable-power-plants/wind-power-plant" />

        {/* Products Sub Items */}
        <Route element={<SubHydrogen />} path="/products/hydrogen/index" />
        <Route element={<SubEnergy />} path="/products/energy/index" />
      </Routes>
      <MyFooter />
    </div >
  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/engServices/220_2114019077.jpg';
import tech2 from '../../../../images/services/engServices/221_1887083413.jpg';
import design2 from '../../../../images/services/engServices/222_1504702805.jpg';
import project2 from '../../../../images/services/engServices/223_2293858001.jpg';
import '../../../../styles/subItem.css'

const TechnicalSupport = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Technical Support (24/7)</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={tech2} className='subitem-img' />
                        </div>
                        <p>When you partner with GEMAN POWER, you gain access to unparalleled engineering and technical support that operates around the clock, across every region of the globe. Our dedicated team of experts is ready to assist you at any hour, ensuring that your projects run smoothly and efficiently, no matter the time zone or location. With our commitment to excellence and customer satisfaction, you can trust us to provide prompt and reliable assistance whenever you need it most.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Engineering Services</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design/design-manufacturing" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Design & Manufacturing</p>
                                <div className='card-image-frame'>
                                    <Image src={design2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design/project-program-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Project & Program Management </p>
                                <div className='card-image-frame'>
                                    <Image src={project2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default TechnicalSupport
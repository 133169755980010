import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import natural from '../../../../../images/services/operation/251_1_1722045232.jpeg';
import coal from '../../../../../images/services/operation/251_2_1137606449.jpg';
import diesel from '../../../../../images/services/operation/251_3_607855838.jpg';
import '../../../../../styles/mainItem.css'

const AllPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Power Plants</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/natural-gas-power-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p> Natural Gas Power Plants </p>
                                    <div className='card-image-frame'>
                                        <Image src={natural} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/coal-fired" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Coal Fired Thermal Power Plants </p>
                                    <div className='card-image-frame'>
                                        <Image src={coal} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/power-plants/diesel-fired" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Diesel-Fired Power Plants </p>
                                    <div className='card-image-frame'>
                                        <Image src={diesel} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AllPower
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import solar2 from '../../../../../images/services/operation/252_1_736731844.jpg';
import wind2 from '../../../../../images/services/operation/252_2_2137635999.jpg';
import hydro2 from '../../../../../images/services/operation/252_3_475746370.jpg';
import geo2 from '../../../../../images/services/operation/252_4_725616556.jpg';
import '../../../../../styles/subItem.css'

const GeothermalPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2> Geothermal Power Plants </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={geo2} className='subitem-img' />
                        </div>
                        <p>We offer Long Term Service Agreement  to ensure maximum availability of our customer’s geothermal power plants and to prevent any performance degradation throughout the power plant’s entire life cycle. Our customers’ peace of mind is guaranteed through Deilir Technical Services; predictable maintenance budgets, inventory control, and proactive contract management. Our contracts are tailored to meet each customer’s individual needs, to adapt to frequently changing market conditions, and to provide the best service value over the plant’s life cycle.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Renewable Power Plants</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/solar-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Solar Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={solar2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/wind-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Wind Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={wind2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/hydroelectric-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Hydroelectric Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={hydro2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default GeothermalPower
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import solar4 from '../../../../../images/services/operation/252_1_736731844.jpg';
import wind4 from '../../../../../images/services/operation/252_2_2137635999.jpg';
import hydro4 from '../../../../../images/services/operation/252_3_475746370.jpg';
import geo4 from '../../../../../images/services/operation/252_4_725616556.jpg';
import '../../../../../styles/subItem.css'

const SolarPower = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Solar Power Plants</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={solar4} className='subitem-img' />
                        </div>
                        <p>GEMAN POWER O&M provides seamless management for your solar assets, requiring minimal effort on your part. The effective O&M of your plant plays a crucial role in its overall profitability, particularly amidst challenges like declining tariffs, heightened margin pressure, and dispersed assets, all of which can significantly impact project IRRs.</p>
                        <p>Drawing from our extensive industry experience as a leading provider of O&M services, GEMAN POWER can enhance your plant's efficiency by optimizing its performance.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Renewable Power Plants</h2>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/wind-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Wind Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={wind4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/hydroelectric-power-plants" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Hydroelectric Power Plants </p>
                                <div className='card-image-frame'>
                                    <Image src={hydro4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/geothermal-power" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Geothermal Power Plants</p>
                                <div className='card-image-frame'>
                                    <Image src={geo4} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default SolarPower
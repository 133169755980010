import React from 'react'
import { Card, Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import asset from '../../images/asset-management.jpeg';

import consulting from '../../images/home/18_1108431911.jpg';
import engService from '../../images/home/19_2114019077.jpg';
import field from '../../images/home/20_2158048149.jpg';
import supply from '../../images/home/21_2151657351.jpg';
import operation from '../../images/home/22_660549931.jpg';
import epc from '../../images/home/23_398992222.jpg';
import hydrogen from '../../images/home/24_2168999393.jpg';
import energy from '../../images/home/25_2084987944.jpg';

const ServicesProducts = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <Container>
                <Row className='justify-content-md-center'>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/consulting" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Consulting</p>
                                <div className='card-image-frame'>
                                    <Image src={consulting} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/engineering-design" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Engineering Services</p>
                                <div className='card-image-frame'>
                                    <Image src={engService} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Field Services</p>
                                <div className='card-image-frame'>
                                    <Image src={field} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Supply Chain Services</p>
                                <div className='card-image-frame'>
                                    <Image src={supply} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/operation-maintenance-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Operations & Maintenance Services</p>
                                <div className='card-image-frame'>
                                    <Image src={operation} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/epc-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>EPC Services</p>
                                <div className='card-image-frame'>
                                    <Image src={epc} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/products/hydrogen/index" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Hydrogen Production & Storage</p>
                                <div className='card-image-frame'>
                                    <Image src={hydrogen} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/products/energy/index" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p style={{ fontSize: '14px' }}>Energy Storage</p>
                                <div className='card-image-frame'>
                                    <Image src={energy} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ServicesProducts
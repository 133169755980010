import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../../images/asset-management.jpeg';
import legend from '../../../../../images/services/operation/250_660549931.jpg';
import solar from '../../../../../images/services/operation/252_1_736731844.jpg';
import wind from '../../../../../images/services/operation/252_2_2137635999.jpg';
import hydro from '../../../../../images/services/operation/252_3_475746370.jpg';
import geo from '../../../../../images/services/operation/252_4_725616556.jpg';
import '../../../../../styles/mainItem.css'

const AllRenewable = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='main-page'>
            <Image src={legend} fluid className="mainItemPage-img" />
            <div className='sub-items'>
                <Container>
                    <h1 className='mb-4'>Renewable Power Plants</h1>
                    <hr className='mb-4' />
                    <Row className='justify-content-md-start'>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/solar-power-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Solar Power Plants </p>
                                    <div className='card-image-frame'>
                                        <Image src={solar} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/wind-power-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Wind Power Plants </p>
                                    <div className='card-image-frame'>
                                        <Image src={wind} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/hydroelectric-power-plants" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Hydroelectric Power Plants </p>
                                    <div className='card-image-frame'>
                                        <Image src={hydro} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                            <Card className='sub-items-card' as={Link} to="/operation-maintenance-services/renewable-power-plants/geothermal-power" onClick={handleScrollToTop}>
                                <Card.Body>
                                    <p>Geothermal Power Plants</p>
                                    <div className='card-image-frame'>
                                        <Image src={geo} rounded className='exec-summary-images2' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AllRenewable
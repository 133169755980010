import React from 'react'
import { Image } from 'react-bootstrap';
import sideMenuLogo from '../../images/menu/side-menu-logo.png'

const AboutSideMenu = ({ handleMenuItemClick, activeMenuItem }) => {
    return (
        <div className="menu d-flex justify-content-center align-items-center flex-column">
            <ul>
                <li onClick={() => handleMenuItemClick('About Us')} className={activeMenuItem === 'About Us' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    About Us
                </li>
                <li onClick={() => handleMenuItemClick('Companies & Affiliates')} className={activeMenuItem === 'Companies & Affiliates' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Companies & Affiliates
                </li>
                <li onClick={() => handleMenuItemClick('Sustainability')} className={activeMenuItem === 'Sustainability' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Sustainability
                </li>
                <li onClick={() => handleMenuItemClick('History')} className={activeMenuItem === 'History' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    History
                </li>
                <li onClick={() => handleMenuItemClick('Quality')} className={activeMenuItem === 'Quality' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Quality
                </li>
                <li onClick={() => handleMenuItemClick('Vision, Mission & Values')} className={activeMenuItem === 'Vision, Mission & Values' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Vision, Mission & Values
                </li>
                <li onClick={() => handleMenuItemClick('Corporate Safety Program')} className={activeMenuItem === 'Corporate Safety Program' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Corporate Safety Program
                </li>
                <li onClick={() => handleMenuItemClick('Leadership')} className={activeMenuItem === 'Leadership' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Leadership
                </li>
                <li onClick={() => handleMenuItemClick('Research & Innovation')} className={activeMenuItem === 'Research & Innovation' ? 'active' : ''}>
                    <Image src={sideMenuLogo} fluid className="menu-icon" />
                    Research & Innovation
                </li>
            </ul>
        </div>
    );
};

export default AboutSideMenu;
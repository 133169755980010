import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../images/asset-management.jpeg';

import legend from '../../../images/products/30_2041844726.jpg';


import img1 from '../../../images/products/34_2084987944.jpg';
import img2 from '../../../images/products/35_asset.jpeg';

import '../../../styles/subItem.css'

const SubEnergy = () => {
    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };
    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4 mb-4">
                    <Col>
                        <h2>Energy Storage</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={img1} className='subitem-img' />
                        </div>
                        <p>In the quest for a sustainable energy future, the efficient storage of renewable energy has emerged as a critical challenge. Recognizing the importance of energy storage in enabling the widespread adoption of renewables, GEMAN POWER embarked on a mission to revolutionize energy storage solutions. This R&D program explores GEMAN POWER's innovative approach to energy storage and its transformative impact on the renewable energy landscape.</p>
                        <p>GEMAN POWER initiated a comprehensive program aimed at developing cutting-edge energy storage systems capable of storing excessive energy, particularly from renewable sources. Through extensive research and development (R&D) efforts, the project sought to create mobile and instant power sources that could facilitate various processes, including the production of hydrogen and beyond.</p>
                        

                        <div className='image-frame'>
                            <Image src={img2} className='subitem-img' />
                        </div>
                    </Col>
                </Row>
                {/* Second Row */}
                {/* <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Energy Storage</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/products/energy" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Energy Storage</p>
                                <div className='card-image-frame'>
                                    <Image src={asset} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row> */}
            </Container>
        </div>
    )
}

export default SubEnergy
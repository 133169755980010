import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/fieldServices/230_2158048149.jpg';
import man3 from '../../../../images/services/fieldServices/231_1740140642.jpg';
import equipment3 from '../../../../images/services/fieldServices/232_175644863_edited.jpeg';
import site3 from '../../../../images/services/fieldServices/233_1080243251.jpg';
import hse3 from '../../../../images/services/fieldServices/234_383464744.jpg';
import construction3 from '../../../../images/services/fieldServices/235_2253475071.jpg';
import commissioning3 from '../../../../images/services/fieldServices/236_2294645377.jpg';
import '../../../../styles/subItem.css'

const ConstructionServices = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Construction Services and Execution</h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={construction3} className='subitem-img' />
                        </div>
                        <p>At GEMAN POWER, our seasoned experts bring extensive experience to field construction execution. From constructability and craft certification to industrial relations, modularization, rigging, and welding services, our team is well-versed in all aspects of the construction process.</p>
                        <p>Throughout every project phase, our construction teams actively contribute to refining the construction scope, streamlining activities, and providing valuable cost-saving insights. Additionally, we are frequently enlisted to address challenges in the field, facilitating swift and effective problem resolution.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Field Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/manpower-supply" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Manpower Supply</p>
                                <div className='card-image-frame'>
                                    <Image src={man3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/equipment-tooling-fleet-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Equipment, Tooling & Fleet Services</p>
                                <div className='card-image-frame'>
                                    <Image src={equipment3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/site-management" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Site Management</p>
                                <div className='card-image-frame'>
                                    <Image src={site3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/health-safety-env-services" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>HSE Services </p>
                                <div className='card-image-frame'>
                                    <Image src={hse3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/field-services/commissioning-turnover" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Commissioning & Turnover</p>
                                <div className='card-image-frame'>
                                    <Image src={commissioning3} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>




                </Row>
            </Container>
        </div>
    )
}

export default ConstructionServices
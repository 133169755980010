
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import logo2 from '../images/geman-power.svg';
import twitter from '../images/social/twitter.png';
import linkedin from '../images/social/linkedin.png';
import facebook from '../images/social/facebook.png';
import instagram from '../images/social/instagram.png';

import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import '../styles/myFooter.css'

const MyFooter = () => {

  const handleScrollToTop = () => {
    window.scrollTo(0, 0)
  };

  return (
    <div>
      <div className='my-line'></div>
      {/* <div>
        <Container>
          <Row>
            <Col className='d-flex justify-content-center align-items-center mb-2'>

            </Col>
          </Row>
        </Container>
      </div> */}
      <div className='footer-bg'>
        <Container className='pt-3 pb-3'>
          <Row>
            <Col xs={12} md={3}>
              <Link to="/" onClick={handleScrollToTop}>
                <img src={logo2} title='logo' className='footer-logo' />
              </Link>
            </Col>
            <Col xs={6} md={3}>
              <div className='site-map'>
                <h5 className='site-map-title'>Consulting</h5>
                <Link to="/consulting/power-plants" className='site-map-link' onClick={handleScrollToTop}>Power Plants</Link>
                <Link to="/consulting/industrial-projects" className='site-map-link' onClick={handleScrollToTop}>Industrial Projects</Link>
                <Link to="/consulting/natural-gas-pipeline-construction" className='site-map-link' onClick={handleScrollToTop}>Natural Gas Pipeline Construction</Link>
                <Link to="/consulting/air-and-gas" className='site-map-link' onClick={handleScrollToTop}>Air and Gas Insulate Substation</Link>
                <Link to="/consulting/high-power-energy" className='site-map-link' onClick={handleScrollToTop}>High Power Energy Transmission Overhead Line</Link>
                <Link to="/consulting/epcm" className='site-map-link' onClick={handleScrollToTop}>EPCM</Link>
              </div>
              <hr />
              <div className='site-map'>
                <h5 className='site-map-title' onClick={handleScrollToTop}>Supply Chain Services</h5>
                <Link to="/supply-chain-services/supply-of-plant-equipment" className='site-map-link' onClick={handleScrollToTop}>Supply of Plant Equipment, Systems and Capital Parts </Link>
                <Link to="/supply-chain-services/procurement-services" className='site-map-link' onClick={handleScrollToTop}>Procurement Services for Material and Consumable Parts </Link>
                <Link to="/supply-chain-services/supplier-contractor-registry" className='site-map-link' onClick={handleScrollToTop}>Supplier and Contractor Registry Services</Link>
              </div>
              <hr />
            </Col>
            <Col xs={6} md={3}>
              <div className='site-map'>
                <h5 className='site-map-title'>Engineering Services</h5>
                <Link to="/engineering-design/technical-support" className='site-map-link' onClick={handleScrollToTop}>Technical Support (24/7)</Link>
                <Link to="/engineering-design/design-manufacturing" className='site-map-link' onClick={handleScrollToTop}>Design & Manufacturing</Link>
                <Link to="/engineering-design/project-program-management" className='site-map-link' onClick={handleScrollToTop}>Project & Program Management </Link>
              </div>
              <hr />
              <div className='site-map'>
                <h5 className='site-map-title'>Operations & Maintenance Services</h5>
                <Link to="/operation-maintenance-services/power-plants" className='site-map-link' onClick={handleScrollToTop}>Power Plants</Link>
                <Link to="/operation-maintenance-services/renewable-power-plants" className='site-map-link' onClick={handleScrollToTop}>Renewable Power Plants</Link>
                <Link to="/operation-maintenance-services/substations" className='site-map-link' onClick={handleScrollToTop}>Substations</Link>
              </div>
              <hr />
              <div className='site-map'>
                <h5 className='site-map-title'>EPC Services</h5>
                <Link to="/epc-services/energy-petroleum-plants" className='site-map-link' onClick={handleScrollToTop}>Energy & Petroleum Plants</Link>
                <Link to="/epc-services/renewable-power-plants" className='site-map-link' onClick={handleScrollToTop}>Renewable Power Plants</Link>
              </div>
              <hr />
            </Col>
            <Col xs={6} md={3}>
              <div className='site-map'>
                <h5 className='site-map-title'>Field Services</h5>
                <Link to="/field-services/manpower-supply" className='site-map-link' onClick={handleScrollToTop}>Manpower Supply</Link>
                <Link to="/field-services/equipment-tooling-fleet-services" className='site-map-link' onClick={handleScrollToTop}>Equipment, Tooling & Fleet Services</Link>
                <Link to="/field-services/site-management" className='site-map-link' onClick={handleScrollToTop}>Site Management</Link>
                <Link to="/field-services/health-safety-env-services" className='site-map-link' onClick={handleScrollToTop}>HSE Services </Link>
                <Link to="/field-services/construction-services-and-execution" className='site-map-link' onClick={handleScrollToTop}>Construction Services and Execution </Link>
                <Link to="/field-services/commissioning-turnover" className='site-map-link' onClick={handleScrollToTop}>Commissioning & Turnover</Link>
              </div>
              <hr />
              <div className='site-map'>
                <Link to="/products/hydrogen/index" className='site-map-link site-map-products' onClick={handleScrollToTop}>Hydrogen Production & Storage</Link>
              </div>

              <hr />
              <div className='site-map'>
                <Link to="/products/energy/index" className='site-map-link site-map-products' onClick={handleScrollToTop}>Energy Storage</Link>
              </div>

            </Col>
          </Row>
        </Container>
      </div>

      <div className='my-footer'>
        <div className='container-footer'>
          <div className='left-bottom'>
            <p>© 2024 Geman Power Corporation. All Rights Reserved.</p>
          </div>
          <div className='custom-icons'>
            <div className='icon-bg'>
              <a href="https://twitter.com/GemanPower" target="_blank" rel="noopener noreferrer">
                <img src={twitter} className='social-media-icon' alt='Twitter' />
              </a>
            </div>
            <div className='icon-bg'>
              <a href="https://www.linkedin.com/company/gemanpower/about/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} className='social-media-icon' alt='LinkedIn' />
              </a>
            </div>
            <div className='icon-bg'>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={facebook} className='social-media-icon' alt='Facebook' />
              </a>
            </div>
            <div className='icon-bg'>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src={instagram} className='social-media-icon' alt='Instagram' />
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default MyFooter
import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import asset from '../../../../images/asset-management.jpeg';
import legend from '../../../../images/services/supply/240_2151657351.jpg';
import supplyOfPlant2 from '../../../../images/services/supply/241_1_3398501.jpg';
import proc2 from '../../../../images/services/supply/242_2439547217.jpg';
import supplier2 from '../../../../images/services/supply/243_1962667825.jpg';
import '../../../../styles/subItem.css'

const Procurement = () => {

    const handleScrollToTop = () => {
        // Smooth scroll to the top of the page
        window.scrollTo(0, 0)
    };

    return (
        <div className='sub-page'>
            <Image src={legend} fluid className="subItemPage-img" />
            <Container>
                {/* First Row */}
                <Row className="mt-4">
                    <Col>
                        <h2>Procurement Services for Material and Consumable Parts </h2>
                        <hr />
                        <div className='image-frame'>
                            <Image src={proc2} className='subitem-img' />
                        </div>
                        <p>Our procurement services at GEMAN POWER extend to the sourcing and acquisition of both material and consumable parts essential for your operations. With meticulous attention to detail and a focus on efficiency, we handle every aspect of the procurement process to ensure timely delivery of quality materials and parts. Our extensive network of trusted suppliers enables us to secure competitive pricing and reliable sources for all your procurement needs. Whether it's raw materials, spare parts, or consumables, you can count on us to streamline the procurement process and optimize your supply chain for maximum efficiency and cost-effectiveness.</p>
                    </Col>
                    <hr />
                </Row>
                {/* Second Row */}
                <Row className='justify-content-md-start mt-3'>
                    <h2 className='mb-4'>Supply Chain Services</h2>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services/supply-of-plant-equipment" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Supply of Plant Equipment, Systems and Capital Parts </p>
                                <div className='card-image-frame'>
                                    <Image src={supplyOfPlant2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} xl={3} className='mb-3'>
                        <Card className='sub-items-card' as={Link} to="/supply-chain-services/supplier-contractor-registry" onClick={handleScrollToTop}>
                            <Card.Body>
                                <p>Supplier and Contractor Registry Framework Services</p>
                                <div className='card-image-frame'>
                                    <Image src={supplier2} rounded className='exec-summary-images2' />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Procurement